@import "Styles/mixin";
@import "Styles/variables";

.need-password__container {
  width: 30.4vw;

  .hr-loop {
    text-align: center;
    @include textStyles("Montserrat arm", 30px, 500, normal, $main-color);
    margin-bottom: 20px;
  }

  .forgotPassword {
    width: 100%;
    @include flexStyles(flex, row, default, flex-end);
    margin-bottom: 30px;

    & a {
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      @include textStyles('Inter', 12px, 500, 12px, $main-color);
      cursor: pointer;
      text-decoration: none;
    }
    
    & a:hover {
      text-decoration: underline;
    }
  }

  .profile-img__container{
    width: 100%;
    @include flexStyles(flex, row, center, center);

    .profile-img{
      @include sizes(150px, 150px);
      margin-bottom: 20px;
      border-radius: 100%;
    }
  }

  .name-surname__container{
    text-align: center;
    @include textStyles("Montserrat arm", 18px, 500, normal, $light-blue);
    margin-bottom: 20px;
  }

  .email-container{
    text-align: center;
    @include textStyles("Montserrat arm", 18px, 500, normal, $light-blue);
    margin-bottom: 20px;

    & span{
      @include textStyles("Montserrat arm", 18px, 500, normal, $create-group-btn-bg);

      &:hover{
        cursor: pointer;
      }  
    }
  }
}
