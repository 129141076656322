@import "../../Styles/mixin";
@import "../../Styles/variables";

.briefInfoCont{
    @include flexStyles(flex, row, center, flex-start);
    cursor:pointer;

    & .profileImg{
        @include sizes(48px, 48px);
        border-radius: 100%;
        margin: 0 12px 0 0;
        object-fit: cover;
    }

    & .personalInfoCont{
        & .name{
           @include textStyles ("Montserrat arm", 15px, 600, 24px, $darkBlue)
        }

        & .email {
            @include textStyles ("Montserrat arm", 12px, 500, 12px, $dark-grey)
        }
        & .role {
            @include textStyles('Montserrat arm', 16px, 400, normal, $Green-role-color);
        }
    }
}