@import "Styles/mixin";
@import "Styles/variables";

.heading {
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 48px;
}

.title {
  @include textStyles("Montserrat arm", 30px, 500, 42px, $darkBlue);
}

.chooseBtn {
  @include textStyles("Montserrat arm", 16px, 700, 16px, $white);
  max-width: 330px;
}
.buttonContent {
  display: flex;
  justify-content: center;
  margin: 32px 0;
}

.employee-list__cont {
  width: 100%;
  @include flexStyles(flex, row, center, flex-start);
  flex-wrap: wrap;
  gap: 40px;
}

@media screen and (max-width: 1154px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

@media screen and (max-width: 920px) {
  .employeesList {
    padding-top: 50px;
    @include flexStyles(flex, row, center, center);
  }

  .employee-list__cont {
    padding: 80px 24px 24px;
    @include flexStyles(flex, row, center, center);
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .buttonContent {
    padding: 0 24px;
  }
}
@media screen and (max-width: 375px) {
  .employee-list__cont {
    padding: 20px 0;
  }
}
