$main-color-gradient: var(
  --liner-and-redial-green,
  radial-gradient(50% 50% at 50% 50%, #488ce5 0%, #005fdb 100%)
);
$main-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
  rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
$status-rejected-bg: rgba(255, 35, 75, 0.2);
$Green-role-color: rgba(34, 197, 94, 1);
$main-color: var(--Main-Blue, #006af5);
$disable-button: var(--Green, #a3aed0);
$errorColor: var(--Error, #b00020);
$darkBlue: var(--black, #1b2559);
$text-grey: var(--Grey, #a3aed0);
$status-finished-color: #57dba6;
$status-rejected-color: #ff234b;
$Green-position-color: #22c55e;
$black: var(--black, #000000);
$white: var(--white, #ffffff);
$status-actual-color: #e68600;
$Gray-employee-color: #2a3342;
$create-group-btn-bg: #006af5;
$group-members-title: #6b7280;
$no-data-description: #b8b8d2;
$status-finished-bg: #eef6f3;
$prime-button-text: #fcfcfd;
$border-light-grey: #f3f4f6;
$card-border-color: #e1e3e8;
$status-actual-bg: #ffdeb0;
$light-grey-time: #bec8dd;
$white-input-bg: #fcfcfd;
$filename-color: #006884;
$no-data-title: #1f1f39;
$employees-bg: #e9edf7;
$border-color: #f6f6f6;
$light-blue-2: #3e98c7;
$light-grey: #a3aed0;
$light-blue: #3c3e56;
$doing-color: orange;
$dark-grey: #707eae;
$dark-blue: #060518;
$grey-blue: #4b5563;
$order_row: #fbfbfb;
$done-color: green;
$input_bg: #edf2f7;
$to-do-color: blue;
$marengo: #45535c;
$green: #006667;
$hierarchySection:#3f5522
