@import '../../Styles/mixin';
@import '../../Styles/variables';

.languages-container {
  @include flexStyles(flex, row, flex-start, flex-start);
  position: relative;
  max-width: 50px;

  & .languages-list__container {
    @include flexStyles(flex, column, flex-start, flex-start);
    position: absolute;
    top: 0;
    right: -40px;

    & img {
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  & .acttive-lang:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 480px) {
  .languages-list__container {
    top: 40px !important;
    right: 0 !important;
  }
}
