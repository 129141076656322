@import "../../Styles/mixin";
@import "../../Styles/variables";

.create-new-group-btn {
  @include textStyles("Montserrat arm", 16px, 700, 16px, $white);
  min-width: 330px;
  padding: 16px 24px;
  background: $create-group-btn-bg;
  border-radius: 90px;
  text-align: center;
  text-decoration: none;
}

.group-page {
  width: 100%;
  background-color: $employees-bg;
  padding: 40px 115px 0 55px;
  min-height: 100vh;
  height: 100%;

  & .searchContainer {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 20px;
  }

  & .heading {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 50px;

    & .title {
      @include textStyles("Montserrat arm", 30px, 500, 42px, $darkBlue);
    }
  }
}

.group-members-cont {
  @include flexStyles(flex, row, flex-start, flex-start);
  flex-wrap: wrap;
  gap: 40px;
}

.mobile-group-content {
  @include flexStyles(flex, column, center, space-between);
  min-height: 94vh;
  height: auto;
  padding: 70px 0 50px 0;
}

.button {
  margin-top: 20px;
}

.header {
  // position: fixed;
  // top: 0;
}

@media screen and (max-width: 1400px) {
  .title {
    @include textStyles("Montserrat arm",
      25px !important,
      500,
      42px,
      $darkBlue );
  }
}

@media screen and (max-width: 1200px) {
  .heading {
    @include flexStyles(flex,
      column-reverse !important,
      flex-start !important,
      space-between);
  }

  .create-new-group-btn {
    margin-bottom: 20px;
  }

  .title {
    @include textStyles("Montserrat arm",
      20px !important,
      500,
      42px,
      $darkBlue );
  }
}

@media screen and (max-width: 1024px) {
  .info-label {
    @include textStyles("Montserrat arm",
      20px !important,
      600,
      36px,
      $light-grey );
  }

  .info-value {
    @include textStyles("Montserrat arm",
      18px !important,
      600,
      36px,
      $dark-grey );
  }
}

@media screen and (max-width: 920px) {
  .mobile-group-content {
    padding: 80px 24px 20px;
  }

  .group-info-container {
    width: 100% !important;
  }

  .title {
    margin-bottom: 16px;
  }

  .information {
    width: 100%;
    border: 1px solid $border-light-grey;
    border-radius: 16px;
    padding: 8px 16px;
    margin-bottom: 24px;
  }

  .info-item {
    @include flexStyles(flex, row, center, space-between);
    padding: 10px 0;

    .info-label {
      @include textStyles("Montserrat arm", 24px, 600, 36px, $light-grey);
    }

    .info-value {
      @include textStyles("Montserrat arm", 24px, 600, 36px, $dark-grey);
    }
  }

  .group-users-title {
    @include textStyles ("Montserrat arm", 16px, 700, 24px, $group-members-title);
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 578px) {
  .group-page {
    padding: 20px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    @include textStyles("Montserrat arm", 16px !important, 700 !important, 24px !important, $darkBlue);
  }

  .info-label {
    @include textStyles("Montserrat arm", 14px !important, 600, 21px !important, $light-grey);
  }

  .info-value {
    @include textStyles("Montserrat arm", 14px !important, 600, 21px !important, $dark-grey);
  }
}

@media screen and (max-width: 320px) {
  .info-item {
    @include flexStyles(flex !important, column !important, flex-start !important, center !important)
  }
}