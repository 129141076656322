@import 'Styles/variables';
@import 'Styles/mixin';

.my-invitations-page {
  width: 100%;
  background-color: $employees-bg;
  padding: 40px 115px 0 55px;
  min-height: 100vh;
  height: 100%;
}

.my-invitations-page__title {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
  margin-bottom: 32px;
}

@media screen and (max-width: 920px) {
  .my-invitations-page {
    width: 100%;
    background-color: $white;
    padding: 0;
  }
}
