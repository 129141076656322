@import '../../Styles/mixin';
@import '../../Styles/variables';

.breadCrumbBox {
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 34px;
}

.absence-detail-page__title {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
  margin-bottom: 20px;
}

.absence-detail-page__history-title {
  @include textStyles('Montserrat arm', 16px, 400, normal, $darkBlue);
  margin-bottom: 24px;
}

.content {
  padding: 24px;
}

@media screen and (max-width: 920px) {
  .absence-detail-page__title {
    @include textStyles('Montserrat arm', 16px, 400, normal, $darkBlue);
    margin-bottom: 16px;
  }
}
