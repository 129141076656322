@import 'Styles/mixin';
@import 'Styles/variables';

.container {
    @include flexStyles(flex, column, center, center);
}

.body {
    @include flexStyles(flex, row, normal, normal);
    border-radius: 15px;
    transition: 1s;
    transform: translateX(15px);

    &:hover .optionsBox {
        opacity: 1;
        visibility: visible;
    }
}

.item {
    padding: 5px 10px 15px;
    @include flexStyles(flex, column, normal, center);
    gap: 20px;
    flex: 1;
    border-radius: 15px;
}

.imageBlock {
    @include flexStyles(flex, column, center, center);
    width: 100%;
    height: 70px;
}

.image {
    width: fit-content;
    max-height: 70px;
    border-radius: 15px;
}

.info {
    @include flexStyles(flex, row, center, center);
    width: 100%;
    background-color: $status-finished-bg;
    border-radius: 15px;
    padding: 5px 10px;
    gap: 20px;
}

.infoData {
    @include flexStyles(flex, column, center, center);
    flex: 1;
    padding: 5px 10px;
    gap: 10px;
}

.expanded {
    @include flexStyles(flex, column, center, center);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid;

    & img {
        cursor: pointer;
        transition: 0.2s;
    }

    & :hover {
        transform: scale(1.1);
    }
}

.sectionName {
    @include textStyles('Montserrat arm', 16px, 600, normal, $darkBlue);
}

.sectionMembers {
    @include textStyles('Montserrat arm', 12px, 400, normal, $light-blue);
}

.memberCount {
    @include textStyles('Montserrat arm', 12px, 600, normal, $filename-color);
}

.addBox {
    width: 100%;
    @include flexStyles(flex, column, center, center);
}

.plusIcon {
    cursor: pointer;
}

.optionsBox {
    height: 100%;
    @include flexStyles(flex, column, center, center);
    gap: 20px;
    padding: 10px 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.optionIcon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: scale(1.1);
     }
}
