@import "../../../../Styles/variables";

.form {
    gap: 1vh;
    margin-top: 3.4vh;
}

.button {
    margin-top: 15px;
}

.form {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $input_bg inset !important;
        -webkit-text-fill-color: $dark-grey !important;
    }
}

@media screen and (max-width: 480px) {
    .form {
        margin-bottom: 32px
    }

}