@import "Styles/mixin";
@import "Styles/variables";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 10px;
}

.loading {
    height: 25vh;
}

.header {
    text-align: center !important;
}

.tableBody {
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}

.orderRow {
    background-color: $order_row;
    margin-top: 10px;
}

@media screen and (max-width:920px) {
    .buttonTitle {
        font-size: 14px;
    }
}