@import 'Styles/variables';
@import 'Styles/mixin';

.user-container-active {
  @include flexStyles(flex, row, center, flex-start);
  margin-bottom: 10px;
  background: $light-grey-time;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
  & img {
    @include sizes(40px, 40px);
    border-radius: 100%;
    margin-right: 10px;
  }

  & .name {
    @include textStyles('Montserrat arm', 14px, 500, normal, $light-blue);
  }
}

.user-container-inactive {
  @include flexStyles(flex, row, center, flex-start);
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  & img {
    @include sizes(40px, 40px);
    border-radius: 100%;
    margin-right: 10px;
  }

  & .name {
    @include textStyles('Montserrat arm', 14px, 500, normal, $light-blue);
  }
}

.mediaPickerContainer {
  width: 100%;
  @include flexStyles(flex, row, center, center);
  margin-bottom: 30px;
}

.mediaPicker {
  width: 140px;
  height: 140px;
}

.buttons-container{
  @include flexStyles(flex, row, center, flex-start);
  gap: 20px
}