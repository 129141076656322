@import 'Styles/mixin';
@import 'Styles/variables';

.container {
   margin: 24px 0 10px;
}

.inputField {
   border-radius: 6px;
   padding: 6px;
  }

