@import 'Styles//mixin';
@import 'Styles//variables';

.container {
  width: 100%;
  @include flexStyles(flex, column, center, flex-start);
}

.linePart {
  display: inline-flex;
  width: 100%;
  align-items: center;
  gap: 24px;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: $text-grey;
}

.text {
  text-align: center;
  @include textStyles('Montserrat arm', 12px, 500, 18px, $text-grey);
  letter-spacing: -0.08px;
}

.social {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.socialIcon {
  width: 41.457px;
  height: 41.457px;
  flex-shrink: 0;
  transition: 0.2s;
  cursor: pointer;
}

// .socialIcon:hover {
//   transform: scale(1.2);
// }
@media screen and (max-width: 1220px) {
  .linePart {
    gap: 8px;
  }
}

@media screen and (max-width: 920px) {
  .text {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 375px) {
  .line {
    display: none;
  }
  .linePart {
    justify-content: center;
  }
}
