@import "../../Styles//mixin";
@import "../../Styles/variables";

.container{
    @include flexStyles(flex,column,center, space-between);
    min-height:84vh;
    padding: 24px 24px 32px;
    gap: 24px;
    background-color: $prime-button-text;
}
.buttonContent{
    width: 100%;
}
.button{
    width: 100%;
}
.cardContent{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.form {
    @include flexStyles(flex,column,center, space-between);
    background-color: $prime-button-text;
    min-height:84vh;
}

@media screen and (max-width:650px) {
    .form{
        flex: 1;
    }
    
}

