@import '../../Styles/mixin';
@import '../../Styles/variables';

.settings-page {
  width: 100%;
  background-color: $employees-bg;
  padding: 40px 115px 0 55px;
  min-height: 100vh;
  height: 100%;
  &__heading {
    @include flexStyles(flex, row, center, space-between);
  }

  &__title {
    @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
    margin-bottom: 32px;
  }
}

.settings-table {
  min-width: 400px;
  width: 40%;

  
  &__connected{
    @include textStyles('Montserrat arm', 16px, 500, 28px, $dark-grey);
    display: flex;
  }
  &__row {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__label {
    @include textStyles('Montserrat arm', 24px, 500, 28px, $dark-grey);
    @include flexStyles(flex, row, center, flex-start);

    & img {
      margin-left: 16px;
    }
  }
}
.modalText{
  margin: 24px 0;
  @include textStyles('Montserrat arm', 16px, 400, normal, $dark-grey);

}
.mobile-settings-page {
  padding: 80px 24px 24px;
}
.dismissalField {
  // background-color: $prime-button-text;
  padding: 0 !important;

  textarea {
    padding: 16px !important;
    flex: 1 !important;
    min-height: 70px;
    max-height: 180px !important
  }
}

@media screen and (max-width: 920px) {
  .settings-table {
    width: 50%;
    margin: auto;
  }
}

@media screen and (max-width: 480px) {
  .settings-table {
    width: 100%;
    min-width: 100%;
    &__connected{
      font-size: 14px;
      img{
        width: 70px;
      }
    }
  }
}
