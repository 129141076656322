@import "../../Styles//mixin";
@import "../../Styles/variables";

.box{
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 5;
}

.container {
    width: 100%;
    padding: 20px 24px;
    @include flexStyles(flex, row, normal, normal);
    background-color: $main-color;
}

.primeryContainer {
    @include flexStyles(flex, row, center, flex-end)
}


.primeryHeaderNav {
    width: 100%;
    @include flexStyles(flex,row,normal,space-between)
}

.iconContainer {
    width: 45px;
    @include flexStyles(flex,row,normal,center)
}

.navIcon {
    width: 24px;
    height: 24px;
 }

.navTitle {
    @include textStyles('Montserrat arm', 20px, 500, normal, $prime-button-text)
}
.logoContainer{
    width: 70vw;
    margin: auto;
}
@media screen and (max-width: 530px){
    .logoContainer{width: 100vw};
}