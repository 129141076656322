@import '../../../../Styles/_mixin.scss';
@import '../../../../Styles/_variables.scss';

.container {
  min-width: 90px;
  max-width: 90px;
  padding-top: 5px;
  text-align: center;
  padding-bottom: 7px;
  border-radius: 14px;
  cursor: pointer;
  @include textStyles('Montserrat arm', 10px, 500, normal, $white);
}

// .actual {
//   color: $status-actual-color;
//   background-color: $status-actual-bg;
// }

// .finished {
//   color: $status-finished-color;
//   background-color: $status-finished-bg;
// }

// .rejected {
//   color: $status-rejected-color;
//   background-color: $status-rejected-bg;
// }
