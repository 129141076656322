@import "Styles/mixin";
@import "Styles/variables";

.mekum-employee-item {
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 20px;

  & .img {
    @include sizes(52px, 52px);
      border-radius: 100%;
      margin-right: 16px;
  };

  &__content{
    @include flexStyles(flex, row, center, flex-start);

    &__name {
        @include textStyles('Montserrat arm', 16px, 600, 24px, $dark-grey);
        margin-bottom: 2px;
      }
  
      &__role {
        @include textStyles(
          'Montserrat arm',
          11px,
          400,
          16.5px,
          $Green-role-color
        );
      }
  }
}

.mekum-employee__list{
  padding-top: 16px;
}

.loading-container{
  width: 100%;
  margin: auto;
  padding: 30px 0;
  @include flexStyles(flex, row, center, center)
}

.search-input-container{
  position: relative;
  width: 100%;
  padding-top: 23px;

  & img{
    position: absolute;
    right: 16px;
    bottom: 6px;

    &:hover{
      cursor: pointer;
    }
  }

  & .search-mekum-input{
    padding: 11px 16px;
    width: 100%;
    border-radius: 6px;
    background-color: $employees-bg;
    border: none;

    &:focus { 
      outline: none !important;
      border-radius: 6px;
   }
  }
}


