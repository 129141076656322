@import '../../Styles/mixin';
@import '../../Styles/variables';

.main {
  min-height: 100vh;
  @include flexStyles(flex, column, normal, space-between);
}

.container {
  background-color: $white;
  display: inline-block;
  padding-top: 40px;
}

.logo {
  padding: 0 40px 20px;
  border-bottom: 1px solid $disable-button;
}

.navMenuContainer {
  padding: 20px 40px 0;

  h3 {
    @include textStyles('Montserrat arm', 18px, 600, normal, $dark-grey);
  }
}

.activeMainMenu {
  background-color: $main-color;
}

.flexStyle {
  display: flex;
  align-items: center;
  gap: 15px;

  & img {
    width: 24px;
  }
}

.accordionSumary {
  padding: 0;
  margin: 0;
  gap: 15px;
}

.mainMenu {
  background-color: $white;
}

.accordion h3 {
  color: $white !important;
}

.navList a {
  text-decoration: none;
  display: block;
  @include textStyles('Montserrat arm', 16px, 500, normal, $text-grey);
  margin-bottom: 17px;
  font-style: normal;
  letter-spacing: -0.32px;
}

.navList {
  padding-left: 30px;
}

.userContent {
  padding: 0 40px;
}

.drawer-logo {
  width: 200px;
}

@media screen and (max-height: 790px) {
  .navMenuContainer {
    // padding-top: 10px;

    h3 {
      font-size: 16px;
    }
  }
  .container {
    padding-top: 30px;
  }
}

@media screen and (max-width: 920px) {
  .navMenuContainer {
    padding: 20px 20px 0px 0px;
  }
}

@media screen and (max-width: 550px) {
  .navMenuContainer {
    h3 {
      @include textStyles('Montserrat arm', 16px, 500, normal, $dark-grey);
    }
  }
}
