@import 'Styles/mixin';
@import 'Styles/variables';

.firstForm {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $input-bg inset !important;
    -webkit-text-fill-color: $dark-grey !important;
  }
}

.firstForm {
  width: 100%;
}

.container {
  @include flexStyles(flex, column, flex-start, normal);
  width: 30.4vw;
  margin-top: 50px !important
}

.title {
  @include textStyles('Montserrat arm', 30px, 500, normal, $darkBlue);
  margin-bottom: 30px;
}

.description {
  @include textStyles('Montserrat arm', 16px, 400, 25px, $text-grey);
  margin-top: 12px;
  white-space: pre-line;
}

.link {
  @include textStyles('Montserrat arm', 16px, 500, normal, $main-color);
  cursor: pointer;
  text-decoration: none;
  margin-left: 8px;
  transition: 0.3s;
}

.email {
  @include textStyles('Montserrat arm', 16px, 400, normal, #2b3674);
}

.link:hover {
  transform: scale(1.05);
  font-weight: 600;
}

.form {
  width: 100%;
  @include flexStyles(flex, column, flex-start, normal);
  margin-top: 5.4vh;
  gap: 2vh;
}

.forgotPassword {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -25px;
}

.forgotPassword a {
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  @include textStyles('Inter', 11px, 500, 12px, $main-color);
  cursor: pointer;
  text-decoration: none;
}

.forgotPassword a:hover {
  text-decoration: underline;
}

.loginButton {
  margin: 20px 0;
}

.mediaPickerContainer {
  width: 100%;
  @include flexStyles(flex, row, center, center);
  margin-bottom: 30px;
}

.mediaPicker {
  width: 140px;
  height: 140px;
}
.companyButtons{
  display: flex;
  width: 100%;
  gap: 20px;
}
.firstForm{
  @include flexStyles(flex, column, flex-start, normal);
  gap: 2vh;
}
.formContainer {
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $input_bg inset !important;
    -webkit-text-fill-color: $dark-grey !important;
  }
}

.registerContainer {
  margin: auto;
  width: 100%;
  @include flexStyles(flex, row, center, center);
}

.inputField {
  background-color: $prime-button-text;
}

.createCompanyForm {
  margin-top: 0px;
}

.createCompanyContainer {
  width: 100%;
}

.addGropContainer {
  @include flexStyles(flex, column, flex-start, normal);
  width: 75%;
  max-height: 100vh;
  gap: 40px;

  & .header {
    @include flexStyles(flex, column, flex-start, normal);
    width: 100%;
    max-height: 100vh;
    gap: 32px;
  }

  & .title {
    @include textStyles('Montserrat arm', 30px, 500, normal, $darkBlue);
    margin-bottom: 0;
  }

  & .describtion {
    width: 100%;
    @include textStyles('Montserrat arm', 16px, 400, normal, $darkBlue);
  }

  & .form {
    width: 100%;
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
  }

  & .inputDiv {
    width: 55%;
  }

  & .inputField {
    background-color: $prime-button-text;
  }

  & .button {
    padding: 13px 40px;
    width: fit-content;
  }
}

//@media

@media screen and (max-width: 1150px) {
  .addGropContainer {
    & .inputDiv {
      width: 65%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: 37.4vw;
  }

  .addGropContainer {
    & .inputDiv {
      width: 70%;
    }
  }
}

@media screen and (max-width: 920px) {
  .container {
    width: 70vw;
    padding: 30px 0;
  }

  .createCompanyContainer{
    padding-top: 0;
  }
  .createCompanyForm{
    padding-bottom: 40px;
  }

  .form {
    gap: 2vh;
  }

  .title {
    @include textStyles('Montserrat arm', 24px, 600, normal, $darkBlue);
  }

  .mediaPickerContainer {
    margin-top: 50px;
  }

  .createCompanyForm {
    margin-top: 0;
  }

  .addGropContainer {
    &.form {
      gap: 0;
    }
  }
}

@media screen and (max-height: 760px) {
  .form {
    gap: 10px;
  }
}

@media screen and (max-width: 710px) {
  .link {
    font-size: 13px;
  }

  .form {
    gap: 1vh;
    margin-top: 3.4vh;
  }

  .forgotPassword {
    margin-top: -18px;
  }
}

@media screen and (max-width: 580px) {
  .title {
    font-size: 24px;
  }

  .link {
    font-size: 13px;
  }

  .addGropContainer {
    width: 100%;
    flex: 1;
    gap: 0;

    & .header {
      gap: 12px;
    }

    & .title {
      font-size: 24px;
    }

    & .describtion {
      width: 70%;
      color: $text-grey;
    }

    & .form {
      flex: 1;
      justify-content: space-between;
    }

    & .inputDiv {
      width: 100%;
    }

    & .inputField {
      background-color: $employees-bg;
    }

    & .button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 530px) {
  .container {
    width: 100%;
    padding: 15px 24px;
  }

  .forgotPassword {
    margin-top: -35px;
  }

  .form {
    gap: 14px;
    margin-top: 6.4vh;
  }

  .mediaPickerContainer {
    margin-top: 0px;
  }
}

@media screen and (max-width: 415px) {
  .forgotPassword {
    margin-top: -17px;
  }
}

@media screen and (max-width: 375px) {
  .container {
    padding: 15px 24px !important;
  }

  .description {
    line-height: 20px;
  }

  .addGropContainer {
    & .describtion {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 340px) {
  .title {
    font-size: 20px;
  }

  .link {
    font-size: 11px;
    margin-left: 4px;
  }
}
