@import "Styles/mixin";
@import "Styles/variables";

.forgetPasswordForm {
    gap: 50px;
    margin: 50px 0 40px;
    width: 100%;
    @include flexStyles(flex, column, center, flex-start);
}

.formContainer {
    width: 100%;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $input_bg inset !important;
      -webkit-text-fill-color: $dark-grey !important;
}
}

.formPassword {
    gap: 30px;
    margin: 30px 0;
}

.button {
    margin: 30px 0 40px;
    width: 100%;
}

.link {
    display: block;
}

.bottomBox {
    @include flexStyles(flex, row, stretch, center);
    width: 100%;
}

.passwordChanged {
    width: 100%;
    height: 100%;
    @include flexStyles(flex, column, flex-start, center)
}

@media screen and (max-width: 920px) {
    .button {
        padding: 40px 0;
    }

    .otpForm {
        margin-top: 70px;
    }

    .otpForm button {
        margin-top: 7px !important;
    }

}


@media screen and (max-width: 550px) {
    .forgetPasswordForm {
        gap: 24px;
        margin: 40px 0;
    }
}