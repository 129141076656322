@import '../../../Styles/mixin';
@import '../../../Styles/variables';

.termsAndConditionsCont {
  width: 100%;
  @include flexStyles(flex, row, flex-start, space-between);
  padding-top: 25px;
}

.description {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: $dark-grey;
}

.link {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: $dark-grey;
  text-decoration: none;
}
