@import '../../../../Styles/variables';
@import '../../../../Styles/mixin';

.container {
    @include flexStyles(flex, column, normal, normal);
    gap: 20px;
}

.formBox {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
}

.description {
    width: 100%;
    @include textStyles("Montserrat arm", 1vw, 400, normal, $darkBlue);
    white-space: pre-wrap;
}

.pensionform {
    width: 80%;
    @include flexStyles(flex, column, normal, normal);
    gap: 15px;
    padding-bottom: 22px;
}

.inputsContainer {
    display: flex;
    gap: 30px;
}

.inputField {
    background-color: $prime-button-text;
}

.inputsContainer>div {
    min-width: 47%;
}

.inputWitdth {
    min-width: 47% !important;
}

.btn {
    padding: 13px 40px;
}

@media screen and (max-width:920px) {
    .inputField {
        background-color: $employees-bg;
    }

}

@media screen and (max-width:680px) {
    .container {
        flex: 1;
        justify-content: space-between;
    }

    .inputsContainer {
        gap: 16px;
    }

    .pensionform {
        width: 100%;
        gap: 0;
    }
}

@media screen and (max-width:520px){
    .container {
        max-width: 100%;
    }
    .inputWitdth {
        width: 100% !important;
    }
}

@media screen and (max-width:480px) {
    .container {
        gap: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}