@import '../../Styles/mixin';
@import '../../Styles/variables';
.container {
  width: 100%;
  min-height: 100vh;
  gap: 35px;
  @include flexStyles(flex, column, normal, normal);
  padding: 0 55px;
  overflow-x: hidden;
}

.mobileBox {
  @include flexStyles(flex, column, normal, normal);
  gap: 28px;
  flex: 1;
  height: 100vh;
}

.mobileContent{
  padding: 65px 24px 0;
  @include flexStyles(flex, column, normal, normal);
 }

.searchContainer {
  width: 100%;
  @include flexStyles(flex, row, center, space-between);
}

.title {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
  letter-spacing: -0.6px;
}

.topDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 920px) {
  .container {
    padding: 0px;
    gap: 0px;
  }
}

@media screen and (max-width: 580px) {
  .mobileContent{
    flex: 1;
   }
}
