@import "Styles/mixin";
@import "Styles/variables";

.plus-container {
  @include flexStyles(flex, row, center, flex-start);
  @include textStyles("Montserrat arm", 14px, 500, 15px, $dark-grey);
  margin: 10px 0 20px 0;
  &:hover {
    cursor: pointer;
  }
}

.add-new-form-icon {
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
}

.header-container {
  width: 90%;
  margin: 0 auto 20px;
  display: flex;
  gap: 15px;
  padding: 15px;
  box-shadow: -1px 2px 11px 7px rgba(15, 105, 135, 0.15);
  position: relative;
}

.remove-header {
  position: absolute;
  right: -8px;
  top: -8px;
  &:hover {
    cursor: pointer;
  }
}
