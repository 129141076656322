@import "../../Styles/mixin";
@import "../../Styles/variables";

.information {
  width: 50%;
  margin-bottom: 38px;
  & .info-item {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 12px;

    & .info-label {
      @include textStyles("Montserrat arm", 24px, 600, 36px, $light-grey);
    }

    & .info-value {
      @include textStyles("Montserrat arm", 24px, 600, 36px, $dark-grey);
    }
  }
}

@media screen and (max-width: 1400px) {
  .information {
    width: 100% !important;
  }
}

@media screen and (max-width: 920px) {
  .information {
    width: 100%;
    border: 1px solid $border-light-grey;
    border-radius: 16px;
    padding: 8px 16px;
    margin-bottom: 24px;
  }

  .info-item {
    @include flexStyles(flex, row, center, space-between);
    padding: 10px 0;

    .info-label {
      @include textStyles("Montserrat arm", 24px, 600, 36px, $light-grey);
    }

    .info-value {
      @include textStyles("Montserrat arm", 24px, 600, 36px, $dark-grey);
    }
  }
}

@media screen and (max-width: 480px) {
    .title {
      @include textStyles("Montserrat arm", 16px !important, 700 !important, 24px !important, $darkBlue);
    }
  
    .info-label {
      @include textStyles("Montserrat arm", 14px !important, 600, 21px !important, $light-grey);
    }
  
    .info-value{
      @include textStyles("Montserrat arm", 14px !important, 600, 21px !important, $dark-grey);
    }
  }

@media screen and (max-width: 320px) {
    .info-item{
      @include flexStyles(flex !important, column !important, flex-start !important, center !important)
    }
  }
