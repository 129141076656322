@import '../../Styles/mixin';
@import '../../Styles/variables';

.employee-item {
  cursor: pointer;
  min-width: 290px;

  & .employee-content {
    padding: 10px;
  }

  & img {
    display: block;
    max-width: 290px;
    width: 100%;
    max-height: 310px;
    min-height: 310px;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    @include textStyles(
      'Montserrat arm',
      24px,
      600,
      35.564px,
      $Gray-employee-color
    );
    margin-bottom: 8px;
  }

  &__role {
    @include textStyles('Montserrat arm', 16px, 400, normal, $Green-role-color);
  }
}
.checkedName {
  color: white;
}
.checked {
  background-color: $dark-grey;
}

.employee-list__cont {
  width: 100%;
  @include flexStyles(flex, row, center, flex-start);
  flex-wrap: wrap;
  gap: 40px;
}

@media screen and (max-width: 920px) {
  .employee-list__cont {
    padding: 80px 24px 24px;
    @include flexStyles(flex, row, center, center);
    width: 100%;
  }
  .employeecontainer{
    margin-top: 120px;
  }
}

@media screen and (max-width: 500px) {
  .employee-list__cont {
    padding: 20px 0;
  }

  .checked {
    background-color: #707eae57;
  }

  .employee-item {
    @include flexStyles(flex, row, center, flex-start);
    width: 100%;
    padding: 5px 24px;

    & img {
      @include sizes(52px, 52px);
      border-radius: 100%;
      margin-right: 16px;
      min-height: unset !important;
    }

    & .employee-content {
      padding: 0px !important;
    }

    &__name {
      @include textStyles('Montserrat arm', 16px, 600, 24px, $dark-grey);
      margin-bottom: 2px;
    }

    &__role {
      @include textStyles(
        'Montserrat arm',
        11px,
        400,
        16.5px,
        $Green-role-color
      );
    }
  }
}
