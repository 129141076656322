@import "../../Styles/mixin";
@import "../../Styles/variables";

.make-employee-page {
  width: 100%;
  background-color: $employees-bg;
  padding: 40px 115px 0 55px;
  min-height: 100vh;
  height: 100%;
}

.inputField {
  background-color: $white !important;
}

.BtnTitle {
  @include textStyles("Montserrat arm", 16px, 600, normal, $employees-bg);
}

.btn {
  width: 100%;
  margin-top: 30px;
}

.page-title {
  padding-top: 34px;
  @include textStyles("Montserrat arm", 30px, 500, 42px, $darkBlue);
  margin-bottom: 30px;
}

.add-employee-form {
  width: 40%;
}

.add-employee-form > div {
  width: 100%;
  @include flexStyles(flex, column, center, flex-start);
  gap: 15px !important;

  .MuiInputBase-root {
    background: $white !important;
  }
}

.settings-table__row {
  @include flexStyles(flex, row, center, space-between);
  width: 100%;
  margin-bottom: 16px;

  :hover{
    cursor: pointer;
  }

  & .settings-table__content {
    @include textStyles("Montserrat arm", 16px, 500, 28px, $dark-grey);
    display: flex;
  }

  & .left-part{
    @include flexStyles(flex, row, center, flex-start);

    & .take-from{
      margin-right: 15px;
    }

    & .from-mekum{
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .add-employee-form {
    width: 100% !important;
  }
  .add-employee-form > div {
    width: 100% !important;
  }
}

@media screen and (max-width: 920px) {
  .make-employee-page {
    width: 100%;
    background-color: $white;
    padding: 24px 19px 32px 29px;
    min-height: 82vh;
    display: block !important;
  }

  .add-employee-form {
    min-height: 86vh !important;
    @include flexStyles(flex !important, column, flex-start, space-between);
    padding-top: 70px;
  }
}
