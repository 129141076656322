@import '../../Styles/mixin';
@import '../../Styles/variables';

.page {
    width: 100%;
    max-width: 100%;
    background-color: $employees-bg;
    min-height: 100vh;
    height: 100%;
    @include flexStyles(flex, row, normal, normal);
    gap: 40px;
}

.body {
    min-height: 100vh;
    max-width: 50vw;
    @include flexStyles(flex, column, normal, normal);
    padding: 40px 40px 0 55px;
    gap: 40px;
}

.rightSideContainer {
    @include flexStyles(flex, column, normal, normal);
    padding: 10px;
    gap: 30px;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 20vw;
    max-width: 20vw;
    background-color: $white;
    position: fixed;
    right: 0;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
}


.types {
    @include flexStyles(flex, row, normal, center);
    flex-wrap: wrap;
    gap: 15px
}

.header {
    @include flexStyles(flex, column, normal, normal);
    gap: 20px
}

.title {
    @include textStyles('Montserrat arm', 30px, 400, 42px, $darkBlue);
}

.describtiom {
    @include textStyles('Montserrat arm', 1.1vw, 400, normal, $darkBlue);
    max-width: 60%;
}

.currentTemplate {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
}

.typeItem {
    padding: 15px;
    @include flexStyles(flex, column, center, center);
    @include textStyles('Montserrat arm', 15px, 400, normal, $darkBlue);
    gap: 10px;
    background-color: $card-border-color;
    width: 100px;
    border-radius: 15px;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
    cursor: pointer;
    transition: 0.2s;
}

.summary {
    width: 100%;
    @include flexStyles(flex, row, center, space-between);
}

.directoryTittle {
    @include textStyles('Montserrat arm', 15px, 600, normal, $group-members-title);
}

.inputIcon {
    max-height: 20px;
    min-width: 20px;
    margin-right: 20px;
}

.checkedTypeItem {
    padding: 15px;
    @include flexStyles(flex, column, center, center);
    @include textStyles('Montserrat arm', 15px, 400, normal, $status-finished-bg);
    gap: 10px;
    background-color: $no-data-description;
    width: 110px;
    border-radius: 15px;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
    cursor: pointer;
    transition: 0.2s;
}

.typeItem:hover {
    transform: scale(1.1);
}

.formContainer {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
    background-color: $prime-button-text;
    border-radius: 15px;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
    padding: 20px;
}

.form {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
}

.formTitle {
    @include textStyles('Montserrat arm', 20px, 600, normal, $marengo);
}


.inputRow {
    @include flexStyles(flex, row, normal, normal);
    gap: 20px;
}

.radioButton {
    @include flexStyles(flex, column, normal, normal);
    gap: 10px;
}

.buttonRow {
    @include flexStyles(flex, row, normal, flex-end);
    gap: 20px;
}

.button {
    width: fit-content;
    padding: 5px;
    font-size: 15px;
}

.configList {
    width: 100%;
    @include flexStyles(flex, column, normal, normal);
    gap: 10px;
}

.config {
    width: 100%;
    @include flexStyles(flex, row, center, space-between);
    background-color: $prime-button-text;
    @include textStyles('Montserrat arm', 15px, 400, normal, $darkBlue);
    padding: 10px;
    border-radius: 10px;
}

.requiredConfig {
    width: fit-content;
    border-radius: 25px;
    text-align: center;
    padding: 2px 5px 2px;
    @include textStyles('Montserrat arm', 12px, 400, normal, $errorColor);
    background-color: $status-actual-color;
}

.notRequiredConfig {
    width: fit-content;
    border-radius: 25px;
    text-align: center;
    padding: 2px 5px 2px;
    @include textStyles('Montserrat arm', 12px, 400, normal, $dark-grey);
    background-color: $light-grey;
}

.modalInner {
    width: 100%;
    @include flexStyles(flex, column, normal, normal);
    gap: 30px
}

.description {
    @include textStyles('Montserrat arm', 16px, 500, normal, $marengo);
}

.styledText {
    @include textStyles('Montserrat arm', 17px, 600, normal, $main_color);
}

.mobileContainer {
    background-color: $employees-bg;
    z-index: 0;
}

.createButtonRow {
    @include flexStyles(flex, row, normal, flex-end);
    gap: 20px;
}

.createButton {
    position: fixed;
    width: fit-content;
    padding: 20px;
    font-size: 15px;
    bottom: 20px;
    right: 35px;
    z-index: 1;
}

.createButton:hover {
    transform: scale(1.05);
}

.drawer {
    @include flexStyles(flex, column, normal, normal);
    padding: 10px;
    gap: 30px;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 20vw;
    max-width: 20vw;
    background-color: $white;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
}

.paginationBox {
    margin-bottom: 
    50px;
}

@media screen and (max-width:1168px) {
    .inputRow {
        flex-direction: column;
    }
}

@media screen and (max-width:920px) {
    .page {
        padding: 0;
        margin: 0;
        flex-direction: column;
        max-width: 100%;
    }

    .body {
        max-width: 100%;
        margin-top: 30px;
        align-items: center;
    }

    .header {
        width: 100%;
    }

    .configList {
        width: 60%;
    }

    .describtiom {
        font-size: 14px;
    }

    .typeItem {
        height: 100px;
    }

    .checkedTypeItem {
        height: 110px;
    }
}

@media screen and (max-width:800px) {
    .configList {
        width: 80%;
    }
}

@media screen and (max-width:770px) {
    .body {
        margin-top: 40px;
    }

    .title {
        font-size: 3.5vw;
        line-height: 10px
    }

    .describtiom {
        font-size: 2vw;
    }

    .drawer {
        min-width: 40vw;
        max-width: 40vw;
    }
}

@media screen and (max-width:630px) {
    .configList {
        gap: 5px;
        width: 100%;
    }

    .drawer {
        min-width: 60vw;
        max-width: 60vw;
    }
}

@media screen and (max-width:470px) {
    .body {
        margin-top: 60px;
        padding: 20px 25px;
    }

    .header {
        gap: 10px;
    }

    .title {
        font-size: 18px;
        line-height: 20px
    }

    .describtiom {
        font-size: 10px;
    }

    .configList {
        width: 100%;
    }
}

@media screen and (max-width:425px) {
    .typeItem {
        padding: 10px;
    }

    .checkedTypeItem {
        padding: 10px;
    }
    .drawer {
        min-width: 70vw;
        max-width: 70vw;
    }
}

@media screen and (max-width:336px) {
    

    .typeItem {
        padding: 10px;
        width: 80px;
    }

    .checkedTypeItem {
        padding: 10px;
        width: 80px;
    }
}