@import 'Styles/mixin';
@import 'Styles/variables';

.container {
  display: flex;
  margin-top: 32px;
  // margin: auto;

  p {
    @include textStyles('Montserrat arm', 14px, 400, normal, #718096);
  }

  a {
    text-decoration: none;
    @include textStyles('Montserrat arm', 14px, 400, normal, $main-color);
    transition: 0.2s;
    margin-left: 5px;
  }

  a:hover {
    // transform: scale(1.1);
    cursor: pointer;
  }
}
@media screen and (max-width: 390px) {
  .container p {
    font-size: 13px;
  }
  .container a {
    font-size: 13px;
  }
}
