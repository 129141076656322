@import 'Styles/variables';
@import 'Styles/mixin';

.container {
  @include flexStyles(flex, column, flex-end, flex-end)
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.defAvatar{
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: $main-color;
  color:#ffff;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}

.onlineIndecator{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(2, 189, 27);
  transform: translateY(-7px);
}