@import 'Styles/mixin';
@import 'Styles/variables';

.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $employees-bg;
  padding: 40px 55px;
}

.send-message__container {
  @include flexStyles(flex, row, center, flex-start);
  padding: 20px 0 0 0;

  &:hover {
    cursor: pointer;
  }

  & img {
    margin-right: 10px;
  }

  & span {
    @include textStyles('Montserrat arm', 18px, 400, 150%, $main-color);
  }
}

.header {
  // position: fixed;
  // top: 0;
  z-index: 3;
}

.employeProfile {
  padding: 0;
}

.breadCrumbBox {
  @include flexStyles(flex, row, center, space-between);
}

.infoContainer {
  @include flexStyles(flex, row, normal, normal);
  gap: 30px;
  width: 75%;
}

.userInfoContainer {
  @include flexStyles(flex, row, normal, normal);
  gap: 30px;
  width: 75%;
}

.title {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
  letter-spacing: -0.6px;
  margin-bottom: 54px;
}

.profileImage {
  width: 290px;
}

.imageUpload {
  width: 290px;
}

.image {
  min-width: 290px;
  max-height: 290px;
  // min-height: 310px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileDescriptions {
  width: 100%;
  @include flexStyles(flex, column, stretch, flex-start);
  gap: 24px;
}

.userProfileInfo {
  width: 100%;
  @include flexStyles(flex, column, stretch, flex-start);
  gap: 24px;
}

.typography {
  @include textStyles('Montserrat arm', 18px, 400, 150%, $group-members-title);
  font-style: normal;
  letter-spacing: 0.3px;
}

.profileName {
  @include textStyles('Montserrat arm',
    24px,
    600,
    35.564px,
    $Gray-employee-color );
  letter-spacing: -0.48px;
  margin-bottom: 4px;
}

.descriptionBox {
  @include flexStyles(flex, row, center, space-between);
}

.employeeInfo {
  @include textStyles('Montserrat arm', 16px, 600, 150%, #1d3a70);
  font-style: normal;
  letter-spacing: 0.3px;
}

.redButton {
  margin-top: 20px;
  border-color: red;
}

.redButtonTitle {
  color: red;
}

.buttons {
  margin-top: 40px;
  max-width: 290px;
}

.make-employee-btn__container {
  @include flexStyles(flex, row, center, flex-start);
  margin-bottom: 30px;

  :hover {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }

  .make-employee-btn__text {
    @include textStyles('Montserrat arm',
      16px,
      600,
      16px,
      $create-group-btn-bg );
  }
}

.remove-employee-btn__container {
  @include flexStyles(flex, row, center, flex-start);
  margin-bottom: 30px;

  :hover {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }

  .make-employee-btn__text {
    @include textStyles('Montserrat arm',
      16px,
      600,
      16px,
      $status-rejected-color );
  }
}

.employeeDetailButtons {
  @include flexStyles(flex, row, normal, normal);
  gap: 20px;

  .button {
    @include textStyles('Montserrat arm', 16px, 400, 150%, $main-color);
    width: fit-content;
  }

  span:hover {
    cursor: pointer;
  }
}

.already-connected {
  @include textStyles('Montserrat arm', 18px, 400, 150%, $group-members-title);
  font-style: normal;
  letter-spacing: 0.3px;
  padding-top: 25px;
}

.plus-icon {
  @include sizes(30px, 30px);
}

@media screen and (max-width: 1380px) {
  .infoContainer {
    width: 90%;
  }

  .image {
    min-width: 200px;
  }
}

@media screen and (max-width: 1225px) {
  .userInfoContainer {
    width: 100%;
  }

  .profileImage {
    width: 220px;
    height: 250px;
  }
}

@media screen and (max-width: 1100px) {
  .infoContainer {
    flex-direction: column;
    width: 80%;
  }

  .userInfoContainer {
    @include flexStyles(flex, column, center, center);
    gap: 30px;
  }

  .userProfileInfo {
    width: 70%;
  }
}

@media screen and (max-width: 920px) {
  .employeProfile {
    padding: 40px 80px;
  }

  .container {
    padding: 0;
    background-color: $border-light-grey;
  }

  .infoContainer {
    width: 100%;
    margin-top: 40px;
  }

  .profileImage {
    margin: auto;
    border-radius: 50%;
    padding: 5px;
    background-color: white;
  }

  .image {
    border-radius: 50%;
    display: block;
  }

  .buttons {
    max-width: none;
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }

  .redButton {
    margin: 0;
    border: none;
  }

  .image {
    min-width: 0;
  }

  .container {
    @include flexStyles(flex, column, stretch, flex-start);
  }

  .employeProfile {
    @include flexStyles(flex, column, stretch, space-between);
    flex: 1;
  }

  .profileName {
    @include textStyles('Montserrat arm', 18px, 400, 150%, #6b7280);
    font-style: normal;
    letter-spacing: 0.3px;
  }
  .employeeDetailButtons {
   padding: 20px;
}
}

@media screen and (max-width: 620px) {
  .userProfileInfo {
    width: 100%;
  }
}

@media screen and (max-width: 570px) {
  .container {
    padding: 0;
    padding-bottom: 30px;
  }

  .profileImage {
    width: 150px;
    height: 150px;
  }

  .employeProfile {
    padding: 40px 24px 0px;
  }

  .employeProfile {
    flex: 0;
  }
}

@media screen and (max-width: 425px) {
  .profileImage {
    width: 100px;
    height: 100px;
  }

  .profileName {
    font-size: 16px;
    font-weight: 400;
  }

  .typography {
    font-size: 14px;
    font-weight: 400;
  }
}

@media screen and (max-width: 375px) {
  // .container {
  //   background-color: $prime-button-text;
  // }
}

@media screen and (max-width: 350px) {
  .descriptionBox {
    align-items: flex-start;
    flex-direction: column;
  }
}