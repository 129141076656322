@import "Styles/variables";
@import "Styles/mixin";

.notification-item-read{
    width: 400px;
    @include flexStyles(flex, row, center, flex-start);
    padding: 5px 10px;
    margin-bottom: 10px;

    &:hover{
        cursor: pointer;
    }
}

.notification-item-unread{
    background: $white;
    width: 400px;
    @include flexStyles(flex, row, center, flex-start);
    padding: 5px 10px;
    margin-bottom: 10px;

    &:hover{
        cursor: pointer;
    }
}

.img{
    @include sizes(40px, 40px);
    border-radius: 100%;
    margin-right: 12px;
}

.content{
    width: 100%;

    & .first-row{
        @include flexStyles(flex, row, center, space-between);

        & .name{
            @include textStyles ("Montserrat arm", 16px, 700, normal, $marengo)
        }

        & .date {
            @include textStyles ("Montserrat arm", 13px, 400, normal, $light-grey-time)
        }
    }

    & .second-row{
        @include textStyles ("Montserrat arm", 14px, 275, 23px, $light-blue);

        & .changedStatus-text{
            font-family: "Montserrat arm"; 
            font-size: 12px;
            font-weight: 400;
            line-height: 137.3%;
        }
    }
}

@media screen and (max-width: 920px){
    .notification-list-container{
        padding: 20px 0 0;
        @include flexStyles(flex, column, center, space-between);
        min-height: 92vh;
    }
}

@media screen and (max-width: 480px){
    .notification-list-container{
        width: 100%;
    }
}

@media screen and (max-width: 320px){
    .first-row{
        @include flexStyles(flex, column !important, flex-start !important, flex-start); 
    }
}