@mixin textStyles ($font-family, $font-size, $font-weight, $line-height, $color) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    color: $color;
}
@mixin backgroundStyles($background-image, $background-size, $background-position, $background-repeat){
    background-image: $background-image;
    background-size: $background-size;
    background-position: $background-position;
    background-repeat: $background-repeat;
}
@mixin flexStyles($flex, $flex-direction, $align-items, $justify-content){
    display: $flex;
    flex-direction: $flex-direction;
    align-items:$align-items ;
    justify-content: $justify-content;
}
@mixin sizes($width, $height){
    width: $width;
    height: $height
}