@import '../../Styles/mixin';
@import '../../Styles/variables';

.detail-info__container {
  width: 645px;
  background: $white;
  border-radius: 12px;
  padding: 0 16px;
  margin-bottom: 30px;

  & .row {
    @include flexStyles(flex, row, center, space-between);
    border-bottom: 1px solid $border-color;
    padding: 16px;

    & .label,
    & .value {
      @include textStyles('Montserrat arm', 14px, 400, normal, $dark-grey);
      max-width: 70%;
      overflow: hidden;
    }
  }

  & .dismissal-row {
    @include flexStyles(flex, row, center, space-between);
    border-bottom: 1px solid $border-color;
    padding: 16px;

    &:last-child {
      @include flexStyles(flex, column, flex-start, center);
      gap: 16px;
    }

    & .label,
    & .value {
      @include textStyles('Montserrat arm', 14px, 400, normal, $dark-grey);
    }
  }
}

@media screen and (max-width: 920px) {
  .detail-info__container {
    width: 100%;
    border: 1px solid $card-border-color;

    & .row {
      border-bottom: 1px solid $border-color;
      padding: 16px;

      & .label,
      & .value {
        @include textStyles('Montserrat arm', 14px, 400, normal, $dark-grey);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .detail-info__container {
    padding: 0;
    & .row {
      padding: 12px;
      & .label,
      & .value {
        @include textStyles('Montserrat arm', 12px, 400, normal, $dark-grey);
      }
    }
  }
}
