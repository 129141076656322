@import "Styles/variables";
@import "Styles/mixin";

.container {
  @include flexStyles(flex, column, stretch, flex-start);
  padding: 16px 12px;
  border: 1px solid $card-border-color;
  background-color: #FFF;
  border-radius: 12px;
  gap: 10px;
  width: 100%
}

.flexStyle {
  @include flexStyles(flex, row, center, space-between);
}

.name {
  @include textStyles('Montserrat arm', 16px, 400, normal, #005FDB);
  letter-spacing: 1px;
}
.dateContent{
  @include textStyles('Montserrat arm', 14px, 500, normal, $dark-grey);
  letter-spacing: 1px;
}
.date{
  @include textStyles('Montserrat arm', 12px, 400, 14.63px, $text-grey);
  letter-spacing: 1px;
}
.datePadding{
  margin-right: 8px;
}


