@import "../../../Styles//mixin";
@import "../../../Styles//variables";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 10px;
}
.body{
    margin-top: 40px;
}
.loading{
    height: 25vh;
}
.button{
    padding-left: 40px;
    padding-right: 30px;
    
}

.buttonTitle {
    @include textStyles('Montserrat arm' !important, 1.1vw, 600 !important, normal, $prime-button-text  !important);
}

.application-row:hover{
    cursor: pointer;
}

@media screen and (max-width:920px) {
    .buttonTitle {
        font-size: 14px;
    }
}