@import 'Styles/variables';
@import 'Styles/mixin';

.container {
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 6px;

}

.border {
    border: 1px solid $main-color
}

.inputBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $prime-button-text inset !important;
        -webkit-text-fill-color: $dark-grey !important;
    }
}

.inputField {
    outline-color: $green;
    border-radius: 6px 0 0 6px;
    background: $text-grey;
    width: 80%;
    padding: 16px;
    align-items: center;
    background-color: $prime-button-text;
}

.inputField input{
    width: 100%;
    background-color: $prime-button-text;
}

.input::placeholder {
    @include textStyles('Montserrat arm', 14px, 400, 15px, $text-grey);
}

.selectBox {
    @include flexStyles(flex, row-reverse, center, flex-start);
    outline-color: $green;
    border-radius: 0 6px 6px 0;
    background: $input_bg;
    min-width: 130px;
    width: 20%;
    position: relative;
    background-color: $prime-button-text;
}

.select {
    stroke-width: 1px;
    width: 100%;
    background-color: $prime-button-text;
    height: 70%;
    border: none;
    outline: none;
    padding-left: 15px;
    appearance: none;
    @include textStyles("Montserrat arm", 14px, 500, 15px, $main-color);
    border-left: 0.5px solid $text-grey;
    cursor: pointer;
    @include backgroundStyles(url("../../../Assets/Icons/selectArrow.png"), 0.65rem auto, right 0.7rem top 50%, no-repeat);
}

.selectPlaceHolder {
    @include textStyles("Montserrat arm", 14px, 800, 15px, $main-color);
}

.border {
    border: 1px solid $main-color
}

.input {
    @include textStyles("Montserrat arm", 14px, 500, 15px, $dark-grey);
    outline: none;
    border: none;
    background: $prime-button-text;
}

.stroke {
    height: 100%;
    width: 1px;
    color: $text-grey;
}

.icon {
    transform: translate(5px, -20px);
    cursor: pointer;
    position: absolute;
}

.error {
    @include flexStyles(flex, row, stretch, space-between);
    width: 95%;
    padding: 0px 10px;
}

@media screen and (max-width: 1100px) {
    select{
        padding-left: 8px;
    }
}
@media screen and (max-width: 920px) {
    .inputBox {
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px $input_bg inset !important;
        }
    }
    
    .inputField {
        background-color: $input_bg;
    }
    
    .inputField input{
        background-color: $input_bg;
    }

    .selectBox {
        background-color: $input_bg;
    }
    
    select {
        background-color: $input_bg;
    }
    
    .input {
        background: $input_bg;
    }
    
    
}
@media screen and (max-width: 530px) {
    .inputField{
        width: 75%;
    }
    .selectBox{
        width: 100px
    }
}
@media screen and (max-width: 355px) {
    select{
        font-size: 12px;
    }
}