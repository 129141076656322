@import 'Styles/variables';
@import 'Styles/mixin';

.container {
    @include flexStyles(flex, column, normal, normal);
    gap: 10px;
}

.imageBox {
    width: 100%;
    max-width: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    background-size: contain;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
}

.iconDiv {
    cursor: pointer;
    transition: 0.2s;

    & :hover {
        transform: scale(1.1);
    }
}

.label {
    @include textStyles('Montserrat arm', 14px, 400, normal, $darkBlue);
}

.colorPicker{
    border-radius: 15px;
    width: 50%;
    block-size: 40px
}