@import "Styles/variables";
@import "Styles/mixin";
.container {
  width: 50%;
  min-height: 100vh;
  background: $main-color-gradient;
  display: flex;
  flex-direction: column;
}
.titleBox {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
  margin: 14% auto 0;
}
.title {
  flex-shrink: 0;
  @include textStyles("DM Sans", 2.8vw, 700, 8vh, $white);
  letter-spacing: -0.4px;
}

.describtion {
  @include textStyles("Inter", 1.25vw, 400, 30px, $white);
}

.picture {
  width: 15.5vw;
  height: 22.4vh;
  @include flexStyles(flex, column, center, center);
  margin: 18vh auto 0;
}

.logo-img {
  max-width: 200px;
  padding: 50px 0 0 50px;
}

@media screen and (max-width: 920px) {
  .container {
    min-height: auto;
    width: 100vw;
    height: 35vh;
    padding: 0 24px;
    position: relative;
  }
  .titleBox {
    gap: 0;
    margin: 0;
    width: 70vw;
    margin: 18px auto;
    padding-right: 40px;
  }
  .describtion {
    line-height: 18px;
    font-size: 14px;
    padding-right: 17%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .title {
    font-size: 30px;
  }
  .picture {
    margin: 0 auto;
    transform: translate(10px, -155px);
    opacity: 0.1;
  }
}
@media screen and (max-width: 650px) {
  .titleBox {
    margin: 14px auto;
  }
}
@media screen and (max-width: 580px) {
  .title {
    font-size: 24px;
  }
  .describtion {
    font-size: 13px;
  }
}
@media screen and (max-width: 530px) {
  .container {
    height: 35vh;
  }
  .titleBox {
    margin: 14px 0;
    padding-right: 0;
    width: 100%;
  }
}
@media screen and (max-width: 315px) {
  .title {
    font-size: 20px;
  }
}

@media screen and (max-height: 1370px) and (max-width:920px) {
  .container {
    height: 23vh;
  }
}

@media screen and (max-height: 920px) and (max-width:920px) {
  .container {
    height: 33vh;
  }
}


