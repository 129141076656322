@import '../../Styles/mixin';
@import '../../Styles/variables';

.teamLead-item {
    cursor: default;
    display: flex;
    align-items: stretch;
    gap: 16px;
    & img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    &__name  {
        @include textStyles('Montserrat arm', 16px, 600, 150%, #1D3A70);
        font-style: normal;
        letter-spacing: 0.3px;
    }
    
    &__role {
        @include textStyles('Montserrat arm', 16px, 400, normal, $Green-role-color);
    }
}

@media screen and (max-width: 480px) {
    .teamLead-item__name{
        font-size: 14px;
    }
    .teamLead-item__role{
        font-size: 14px;
    }
}