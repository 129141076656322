@import 'Styles/variables';
@import 'Styles/mixin';

.container {
    width: 100%;
    height: 100vh;
    @include flexStyles(flex, row, default, default);
  }
  
  @media screen and (max-width: 920px) {
    .container {
      flex-direction: column;
    }
  }