@import "../../Styles/mixin";
@import "../../Styles/variables";

.group-item {
  min-width: 30%;
  max-width: 30%;
  // width: 100%;
  background: $white;
  position: relative;
  padding: 40px 24px 32px 24px;
  border-radius: 6px;
  text-decoration: none;

  &__circle-cont {
    @include sizes(60px, 60px);
    @include flexStyles(flex, row, center, center);
    border-radius: 100%;
    background-color: $white;
    position: absolute;
    left: 30px;
    top: -25px;

    & .group-item__circle {
      @include sizes(48px, 48px);
      background-color: $dark-grey;
      border-radius: 100%;
    }
  }

  .first-row {
    @include flexStyles(flex, row, center, space-between);

    & .group-name {
      @include textStyles(
        "Montserrat arm",
        26px,
        700,
        30px,
        $Gray-employee-color
      );
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    & .members-count-cont {
      @include flexStyles(flex, row, center, center);
      margin-bottom: 24px;

      & .person-icon {
        margin-right: 8px;
      }

      & .members-count {
        @include textStyles("Montserrat arm", 16, 500, normal, $dark-grey);
      }
    }
  }

  .second-row {
    @include flexStyles(flex, row, center, space-between);

    & .label {
      @include textStyles("Montserrat arm", 20px, 400, 32px, $light-grey);
    }

    & .name {
      @include textStyles("Montserrat arm", 18px, 500, 28.8px, $dark-grey);
    }
  }
}

.group-list-cont {
  @include flexStyles(flex, row, flex-start, flex-start);
  gap: 55px;
  flex-wrap: wrap;
}

.empty-groups-container{
  width: 100%;
  @include flexStyles(flex, row, center, center);
  padding-top: 200px;
}

@media screen and (max-width: 1600px) {
  .group-item {
    min-width: 45%;
  }
}

@media screen and (max-width: 980px) {
  .group-item {
    min-width: 60%;
    box-shadow: 0px 0.817px 1.635px 0px rgba(85, 105, 135, 0.15);
    padding: 32px 15px 19px 16px;
    
  }
}

@media screen and (max-width: 920px) {
  .group-item {
    min-width: 40%;
    box-shadow: 0px 0.817px 1.635px 0px rgba(85, 105, 135, 0.15);
    padding: 32px 15px 19px 16px;
  }

  .group-list-cont {
    padding: 100px 0 0 20px;
    width: 100% !important;
    @include flexStyles(flex, row, center, center);
  }

  .pagination-cont{
    @include flexStyles(flex, row, center, center);
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 578px) {
  .group-item {
    min-width: 80%;    
  }
}

@media screen and (max-width: 480px) {
  .group-list-cont {
    padding: 80px 24px 24px;
    
  }

  .group-item {
    width: 100%;
  }

  .group-item {  
    &__circle-cont {
      @include sizes(40px, 40px);
    }
  }

  .group-name {
    @include textStyles(
      "Montserrat arm",
      22px,
      700,
      normal,
      $Gray-employee-color
    );
  }

  .group-item__circle{
    @include sizes(40px !important, 40px !important)
  }

  .person-icon{
    @include sizes(20px, 20px)
  }

  .label {
    @include textStyles("Montserrat arm", 16px !important, 400, 25.6px !important, $light-grey);
  }

  .name {
    @include textStyles("Montserrat arm", 14px !important, 500, 22.4px !important, $dark-grey);
  }
}
