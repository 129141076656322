@import "Styles/mixin";
@import "Styles//variables";

.container {
    width: 29.4vw;
}

.form {
    @include flexStyles(flex, column, flex-start, flex-start);
    width: 100%;
    gap: 12px;
    margin-top: 30px;
}

.formBox {
    width: 100%;

}

.inputBox {
    @include flexStyles(flex, column, stretch, space-between);
    width: 100%;
    max-height: 350px;
    gap: 20px;
}

.smallButtonBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    align-items: flex-end;
    margin-top: 10px
}

.scroll {
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.form ::-webkit-scrollbar {
    width: 6px;
}

.form ::-webkit-scrollbar-thumb {
    background-color: $main-color;
    border-radius: 10px;
}

.form ::-webkit-scrollbar-track-piece {
    background-color: $input_bg;
    border-radius: 10px;
}

@media screen and (max-width: 1200px) {
    .container {
        width: 37.4vw;
    }
}

@media screen and (max-width: 925px) {
    .container {
        width: 42.4vw;
    }
}

@media screen and (max-width: 820px) {
    .container {
        width: 70vw;
        height: 100%;
        @include flexStyles(flex, column, stretch, flex-start);
        padding-top: 32px;
    }

    .smallButtonBox {
        align-items: center;
    }

    .form {
        justify-content: space-between;
        height: 100%;
        padding-bottom: 32px;
    }
}

@media screen and (max-width: 660px) {
    .container {
        padding: 24px;
        width: 90vw;
    }

    .form {
        margin-top: 0
    }

}

;

// @media screen and (max-width: 530px) {
//     .container {
//         width: 90vw;
//     }

// }