.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.navBar{
   width: 31.25vw;
   height: 100vh;
}

@media screen and (max-width:920px) {
    .container{
        flex-direction: column;
    }
}

