.container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width:920px) {
    .container{
        flex-direction: column;
    }
    .forgetPasswordContainer{
        justify-content: space-between;
        height: 100%;
        padding-top: 40px!important;
        padding-bottom: 54px!important;
    }
}

