@import "../../Styles/mixin";
@import "../../Styles/variables";

.history-item__container {
  width: 500px;
  @include flexStyles(flex, row, flex-start, flex-start);

  .img-container {
    .profile-img {
      @include sizes(32px, 32px);
      border-radius: 100%;
      margin-right: 8px;
    }
  }

  & .user-info {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 10px;

    .left-part {
      @include flexStyles(flex, row, center, flex-start);
      
      .user-name {
        @include textStyles("Montserrat arm", 14px, 500, 137.3%, $dark-blue);
        margin-right: 5px;
      }

      .application-status {
        @include textStyles("Montserrat arm", 14px, 275, 23px, $light-blue);
      }
    }
    
    .application-action-time {
      @include textStyles("Montserrat arm", 12px, 275, normal, $light-blue);
    }
  }
  
  & .statuses-container {
    @include flexStyles(flex, row, center, flex-start);
    margin-bottom: 10px;

    .arrow {
      padding: 0 10px;
    }
  }

  & .description {
    @include textStyles("Montserrat arm", 12px, 400, normal, $text-grey);
  }

  .content{
    flex: 1;
  }

  .name-surname{
    @include flexStyles(flex, row, normal, flex-start);
  }
}

@media screen and (max-width: 578px) {
  .history-item__container {
    width: 100% !important;
  }

  .name-surname{
    @include flexStyles(flex, column !important, normal, flex-start);
  }
}

@media screen and (max-width: 375px) {
  .user-name {
    @include textStyles(
      "Montserrat arm",
      12px !important,
      500,
      137.3%,
      $dark-blue
    );

    .application-status {
      @include textStyles(
        "Montserrat arm",
        12px !important,
        275,
        23px,
        $light-blue
      );
    }
  }
}
