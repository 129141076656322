.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 920px) {
  .container {
    flex-direction: column;
  }
}
