@import '../../../Styles/variables';
@import '../../../Styles/mixin';

.container {
  @include flexStyles(flex, column, normal, normal);
  width: 100%;
}

.box {
  @include flexStyles(flex, column, normal, normal);
  gap: 4vh;
}

.header {
  width: 100%;
  @include flexStyles(flex, column, normal, normal);
  gap: 16px;
  white-space: pre-wrap;
}

.title {
  @include textStyles('Montserrat arm', 20px, 600, normal, $darkBlue);
}

.describtion {
  @include textStyles('Montserrat arm', 1vw, 400, normal, $darkBlue);
}

.form {
  @include flexStyles(flex, row, center, normal);
  gap: 30px;
}

.datePicker {
  @include flexStyles(flex, row, normal, normal);
  gap: 30px;
  flex: 1;
}

.inputField {
  background-color: $prime-button-text;
}

.totalItemBox {
  gap: 8px;
}

.totalLabel {
  @include textStyles('Montserrat arm', 14px, 400, normal, $darkBlue);
  transform: translateY(-2px);
}

.totalInput {
  @include flexStyles(flex, column, center, space-between);
  background: $prime-button-text;
  border-radius: 6px;
  margin-top: 8px;
  margin-bottom: 12px;
  width: 140px;
  padding: 18px;
}

.totalResult {
  @include textStyles('Montserrat arm', 14px, 500, 15px, $dark-grey);
  min-height: 14px;
}

.BtnTitle {
  @include textStyles('Montserrat arm', 16px, 600, normal, $employees-bg);
}

.buttonContent {
  @include flexStyles(flex, row, center, flex-start);
  gap: 20px;
  padding-top: 10px;
}

.withoutBtn {
  padding: 12px 30px;
  width: auto;
  border-color: $main-color;
}

.withoutBtnTitle {
  color: $main-color;
  @include textStyles('Montserrat arm', 16px, 600, normal, $main-color);
}

.absenceReason {
  margin-bottom: 10px;
  width: 100%;
}

.dismissalField {
  background-color: $prime-button-text;
  padding: 0 !important;
  textarea {
    padding: 16px !important;
    flex: 1 !important;
    min-height: 180px !important;
  }
}

.modalTextArea {
  background-color: $employees-bg;
  padding: 0 !important;
  textarea {
    padding: 16px !important;
    flex: 1 !important;
    min-height: 180px !important;
  }
}

.current-status:hover {
  cursor: pointer;
}

.availableDays{
  @include textStyles('Montserrat arm', 14px, 400, normal, $darkBlue);
}

@media screen and (max-width: 920px) {
  .container {
    @include flexStyles(flex, column, center, normal);
    padding-top: 20px;
    flex: 1;
  }

  .box {
    width: 50%;
  }

  .describtion {
    font-size: 12px;
    color: $text-grey;
  }

  .formBox {
    flex: 1;
  }

  .form {
    @include flexStyles(flex, row, center, normal);
    flex-direction: column;
    gap: 16px;
  }

  .datePicker {
    width: 100%;
  }

  .inputField {
    background-color: $employees-bg;
  }

  .totalItemBox {
    width: 100%;
  }

  .totalInput {
    width: 100%;
    background-color: $employees-bg;
  }

  .dismissalField {
    background-color: $employees-bg;
  }
  .withoutBtn {
    display: none;
  }
}

@media screen and (max-width: 810px) {
  .box {
    width: 60%;
  }
}

@media screen and (max-width: 650px) {
  .box {
    width: 80%;
  }
}

@media screen and (max-width: 580px) {
  .container {
    padding-top: 0;
  }

  .formBox {
    @include flexStyles(flex, column, normal, space-between);
  }

  .box {
    width: 100%;
    flex: 1;
  }

  .datePicker {
    gap: 10px;
  }

  .footerBox {
    @include flexStyles(flex, column, normal, space-between);
    flex: 1;
  }

  .buttonContent {
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }

  .withoutBtn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .box {
    padding: 33px 0;
  }

  .inputHalfWidth {
    width: 60%;
  }

  .dateInput {
    width: 42vw;
  }

  .dismissalField {
    textarea {
      min-height: 140px !important;
    }
  }
}
