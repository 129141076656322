@import 'Styles/variables';
@import 'Styles/mixin';

.container{
  width: 100%;
  height: 100%;
  @include flexStyles(flex,column,normal,normal)
}

.uploadUserImgContainer{
  width: 100%;
  height: 100%;
  @include flexStyles(flex,column,normal,normal)
}
.frame {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.image{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover; 
  display: flex;
  align-items: center;
  background-size: contain;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
}

.userImage {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  display: flex;
  align-items: center;
  background-size: contain;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
}
.imageBox {
 width: 100%;
 height: 100%;
}

.userImageBox {
  width: 290px;
  height: 310px;
}

.iconDiv{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.cameradiv{
  background-color:$prime-button-text;
  transform: translate(-9px,-32px) ;
  border-radius: 30%;
}

@media screen and (max-width:1225px) {
  .userImageBox {
    width: 240px;
    height: 270px;
  }
}

@media screen and (max-width:1100px) {
  .userImageBox {
    width: 220px;
    height: 220px;
  }
  .userImage {
    border-radius: 50%;
  }
}

@media screen and (max-width:620px) {
  .uploadUserImgContainer{
    align-items: center;
  }
  .userImageBox {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width:425px) {
  .userImageBox {
    width: 100px;
    height: 100px;
  }
}