@import "Styles/mixin";
@import "Styles/variables";

.mention-input__component {
  width: 100%;

  .mentions-input {
    background: $input_bg;
    padding: 16px !important;
    margin-bottom: 10px;

    & textarea {
      padding: 16px !important;
      color: $dark-grey;
      border: none;
    }
  }

  .mention-input__label {
    @include textStyles("Montserrat arm", 14px, 400, normal, $darkBlue);
    display: block;
    margin-bottom: 5px;
  }

  .mention-input-error__message {
    color: $errorColor;
    @include textStyles("Inter", 11px, 400, 12px, $errorColor);
    margin-bottom: 15px;
  }
}
