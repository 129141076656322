@import "../../Styles/mixin";
@import "../../Styles/variables";

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.mobileHeader {
  position: fixed;
  z-index: 1;
}

@media screen and (max-width: 920px) {
  .container {
    flex-direction: column;
  }
}
