@import "../../Styles/mixin";
@import "../../Styles/variables";

.container {
  @include flexStyles(flex, row, center, center);
  width: 100%;
}

.heightContainer{
  display: flex !important;
}

.mainContainer .MuiBox-root.css-8atqhb .MuiBox-root.css-19kzrtu{
  padding: 0!important;
}
.Tabs-root:hover {
  border-color: $dark-grey;
}

