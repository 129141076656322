@import '../../../Styles/mixin';
@import '../../../Styles/variables';

.container {
  min-height: 100vh;
  @include flexStyles(flex, column, center, center);
  background: #fff;
  padding: 5.4vh 0;
}

.containerWithSkip {
  min-height: calc(100vh - 80px);
  @include flexStyles(flex, column, center, center);
  background: #fff;
  padding: 5.4vh 0;
}

.skip {
  padding: 52px 52px 0 0;
  text-align: right;
  @include textStyles('Montserrat arm', 16px, 500, 25px, $create-group-btn-bg);
  text-decoration: none;
}
.rightSide {
  @include flexStyles(flex, column, normal, normal);
  width: 50%;
}

@media screen and (max-width: 920px) {
  .rightSide {
    width: 100%;
  }
  .container {
    min-height: unset;
    @include flexStyles(flex, column, center, flex-start);
  }
}

@media screen and (max-width: 480px) {
  .skip {
    padding-top: 100px;
  }
}