@import 'Styles/mixin';
@import 'Styles/variables';


.body {
   @include flexStyles(flex,column,normal,normal);
   gap: 40px;
   width: 100%;
}
.form {
   width: 100%;
   @include flexStyles(flex,column,normal,normal);
   gap: 10px;
}

.buttonsRow{
    width: 100%;
    @include flexStyles(flex,row,normal,flex-end);
    gap: 10px;
 } 

 .button{
    width: fit-content;
    padding: 5px 15px;
 }

 .button:hover{
   transform: scale(1.05)
}

 .description{
   @include textStyles('Montserrat arm', 16px, 500, normal, $marengo);
 }

 .styledText{
   @include textStyles('Montserrat arm', 17px, 600, normal, $main_color);
 }

 @media  screen and (max-width:390px) {
   .button{
     padding: 5px;
   }
 }