@import 'Styles/variables';
@import 'Styles/mixin';

.header {
    // position: fixed;
    // z-index: 1;
}

.chatContainer {
    width: 100vw;
    max-height: 70vh;
    min-height: 70vh;
    margin-top: 100px;
    padding: 0 10px;
    @include flexStyles(flex, column, normal, normal);
}

.chatListContainer {
    width: 100vw;
    height: 80vh;
    max-height: 80vh;
    margin-top: 65px;
    padding: 0 10px;
    @include flexStyles(flex, column, normal, normal);
}

.devider {
    width: 1px;
    background: $dark-grey;
}

.chat-list__container {
    padding: 10px 10px;
    max-height: 80vh;
    overflow: scroll;
}

.create-group-chat-btn__cont {
    @include flexStyles(flex, row, center, flex-start);
    padding: 10px;
    margin-top: 15px;

    &:hover {
        cursor: pointer;
    }

    & span {
        @include textStyles('Montserrat arm', 16px, 600, normal, $main-color);
    }
}

.plus-icon {
    @include sizes(20px, 20px);
    margin-right: 5px;
}

@media screen and (max-width:650px){
    .chat-list__container{
        height: 75vh;
        max-height: 70vh;
    }
}