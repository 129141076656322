@import 'Styles/mixin';
@import 'Styles/variables';

.container {
    @include flexStyles(flex, column, center, center);
}

.body {
    @include flexStyles(flex, row, normal, normal);
    border-radius: 15px;
    transition: 1s;
    transform: translateX(15px);

    &:hover .optionsBox {
        opacity: 1;
        visibility: visible;
    }
}

.item{
    padding: 15px;
    @include flexStyles(flex, row, normal, normal);
    gap: 20px;
    border-radius: 15px;
    background-color: $filename-color;
}

.imageBlock {
    @include flexStyles(flex, column, center, center);
    width: 50px;
    height: 50px;
}

.image{
    width: 50px;
    max-height: 50px;
    border-radius: 50%;
}

.info{
    @include flexStyles(flex, column, center, space-between);
}

.orgName{
    @include textStyles('Montserrat arm', 14px, 600, normal, $white);
}

.employee{
    @include textStyles('Montserrat arm', 12px, 400, normal, $light-grey-time);
}

.addBox{
    @include flexStyles(flex, column, center, center);
}

.plusIcon{
    cursor: pointer;
}

.optionsBox {
    height: 100%;
    @include flexStyles(flex, column, center, center);
    gap: 15px;
    padding: 10px 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.optionIcon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
     }
}