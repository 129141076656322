@import "../../Styles/mixin";


.container {
    width: 100%;
    flex: 1;
    @include flexStyles(flex,column,normal,normal);
}

.form{
    width: 50%;
}

@media screen and (max-width: 1370px) {
    .form{
        width: 60%;
    }
}

@media screen and (max-width: 1210px) {
    .form{
        width: 70%;
    }
}

@media screen and (max-width: 1102px) {
    .form{
        width: 75%;
    }
}

@media screen and (max-width: 1062px) {
    .form{
        width: 80%;
    }
}

@media screen and (max-width: 1019px) {
    .form{
        width: 85%;
    }
}

@media screen and (max-width: 920px) {
    .form{
        width: 60%;
    }
}

@media screen and (max-width: 660px) {
    .form{
        width: 100%;
    }
}

@media screen and (max-width: 920px){
  .container{
    align-items: center;
    background-color: white;
    padding-top: 50px;
  }
}