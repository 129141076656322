@import 'Styles/mixin';
@import 'Styles/variables';

.my-invitations__list {
  width: 700px;
}

.my-invitation-item__container {
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 30px;
}

.my-invitation-item__content {
  @include flexStyles(flex, row, normal, space-around);
}

.img {
  @include sizes(50px, 50px);
  border-radius: 100%;
  margin-right: 12px;
}

.info {
  @include flexStyles(flex, column, flex-start, space-around);
}

.name-time {
  .name {
    @include textStyles('Montserrat arm', 16px, 700, normal, $marengo);
    margin-right: 15px;
  }
  .time {
    @include textStyles('Montserrat arm', 13px, 400, normal, $light-grey-time);
  }
}

.author-role {
  @include textStyles('Montserrat arm', 13px, 400, normal, $light-grey-time);

  & span {
    @include textStyles('Montserrat arm', 14px, 500, normal, $dark-grey);
  }
}

.my-invitation-item__buttons {
  @include flexStyles(flex, row, center, flex-start);

  .button-container {
    @include flexStyles(flex, row, center, flex-start);

    & img {
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;
    }

    & .accept-btn-text {
      @include textStyles(
        'Montserrat arm',
        16px,
        600,
        24px,
        $Green-position-color
      );
    }

    & .decline-btn-text {
      @include textStyles(
        'Montserrat arm',
        16px,
        600,
        24px,
        $status-rejected-color
      );
    }
  }

  & > div:first-child {
    margin-right: 30px;
  }
}

.accepted_text {
  @include textStyles('Montserrat arm', 16px, 600, 24px, $Green-position-color);
}

.rejected_text,
.canceled_text {
  @include textStyles(
    'Montserrat arm',
    16px,
    600,
    24px,
    $status-rejected-color
  );
}

@media screen and (max-width: 920px) {
  .my-invitations__list {
    width: 100%;
    padding: 80px 20px 30px;
  }
}

@media screen and (max-width: 768px) {
  .my-invitation-item__container {
    @include flexStyles(flex, column, flex-start, space-between);
    margin-bottom: 20px;
  }

  .my-invitation-item__buttons {
    padding: 10px 0 0 60px;
  }

  .rejected_text,
  .canceled_text,
  .accepted_text {
    padding: 10px 0 0 60px;
  }
}

@media screen and (max-width: 578px) {
  .my-invitations__list {
    @include flexStyles(flex, column, flex-start, flex-start);
    min-height: 90vh;
  }

  .my-invitation-item__container {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .name-time {
    @include flexStyles(flex, column, flex-start, space-between);
  }
}

@media screen and (max-width: 375px) {
  .my-invitation-item__content {
    @include flexStyles(flex, column, center, space-between);
    width: 100%;
  }

  .info {
    @include flexStyles(flex, column, center, space-around);
    margin-bottom: 10px;
  }

  .name {
    margin-bottom: 5px;
  }

  .name-time {
    @include flexStyles(flex, column, center, space-between);
    margin-bottom: 5px;
  }

  .author-role {
    text-align: center;
  }

  .rejected_text,
  .canceled_text,
  .accepted_text {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .img {
    margin: 0 0 5px 0;
  }

  .my-invitation-item__buttons {
    width: 100%;
    padding: 0;
    @include flexStyles(flex, row, center, space-between);
  }
}
