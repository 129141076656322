@import 'Styles/mixin';
@import 'Styles/variables';

.container {
  width: 50%;
}

.inputField {
  background-color: $prime-button-text;
}
.email {
  border-radius: 6px;
  padding: 6px;
 }

.emailInput {
  width: 100%;
  margin-bottom: 15px;
}
.emailForm{
  margin: 24px 0 10px;
}
.editEmailIcon{
  cursor: pointer;
}
.inputBox {
  border: 1px solid transparent;
  width: 100%;
  margin-top: 8px;
  @include flexStyles(flex, row, center, space-between);
  @include textStyles('Montserrat arm', 14px, 500, 15px, $dark-grey);
  padding: 16px;
}

.label {
  @include textStyles('Montserrat arm', 14px, 400, normal, $darkBlue);
}

@media screen and (max-width: 920px) {
  .container {
    width: 50%;
  }

  .inputField {
    background-color: $employees-bg;
  }
}

@media screen and (max-width: 650px) {
  .inputBox {
    padding: 12px;
  }
}

@media screen and (max-width:620px) {
  .container {
    width: 75%;
  }
}

@media screen and (max-width:520px) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .inputBox {
    padding: 12px 5px;
  }
}