@import '../../Styles/mixin';
@import '../../Styles/variables';

.breadCrumbBox {
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 34px;
}

.pension-detail-page__title {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
  margin-bottom: 20px;
}

.content {
  padding: 24px;
}

.files-container{
  display: flex;
  flex-wrap: wrap;
}

.files-item{
  & a {
    text-decoration: none;
    color: unset;
    @include flexStyles(flex, row, center, center);
    & img{
      margin-right: 4px;
    }
  
    & .filename{
      @include textStyles ('Montserrat arm', 12px, 400, 161.104%, $filename-color) 
    }
  }
}

@media screen and (max-width: 920px) {
  .pension-detail-page__title {
    @include textStyles('Montserrat arm', 16px, 400, normal, $darkBlue);
    margin-bottom: 16px;
  }
}
