@import 'Styles/mixin';
@import 'Styles/variables';

.page {
    background-color: $employees-bg;
    min-height: 100vh;
    height: max-content;
    // position: static;
    // width: 100%;
      height: 100%;
    @include flexStyles(flex, column, normal, normal);
}

.header {
    @include flexStyles(flex, column, normal, normal);
    gap: 20px;
    padding: 40px 40px 0px;
}

.title {
    @include textStyles('Montserrat arm', 30px, 400, 42px, $darkBlue);
}

.describtiom {
    @include textStyles('Montserrat arm', 1.1vw, 400, normal, $darkBlue);
    max-width: 60%;
}

.body {
    width: 100%;
    padding: 15px;
    min-height: 70%;
    margin-top: 50px;
    @include flexStyles(flex, column, normal, normal);
    overflow-x: auto;
    overflow-y: hidden;
}
.hierarchyTree{
    flex-grow: 1;
    margin-top: 0;
}

.noData {
    margin-top: 50px;
}

.sliderBox{
    padding: 0 40px 0;
    @include flexStyles(flex, row, center, center);
    width: 100%;
    margin-top: 25px;
    position: sticky;
    background-color: $employees-bg;
    top :0;
    z-index: 10;
}

.slider{
    width: 150px;
    background-color:$employees-bg; ;
}