@import '../../Styles/mixin';
@import '../../Styles/variables';

.container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  gap: 35px;
  @include flexStyles(flex, column, normal, normal);
  padding: 40px 55px;
  background-color: $input_bg;
  overflow-x: hidden;
}

.searchContainer {
  width: 100%;
  @include flexStyles(flex, row, center, space-between);
}

.itemcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.textbtn {
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 54px;
  padding-bottom: 30px;
  border-bottom: 2px solid $group-members-title;
}

.btn {
  width: 348px;
}

.emptext {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
}

.employeeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.filterRow {
  @include flexStyles(flex, row, center, normal);
  @include textStyles('Montserrat arm', 20px, 500, 42px, $main-color);
  gap: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.mobileContainer {
  background-color: $employees-bg;
  z-index: 0;
}

.mobileFilter {
  width: 100%;
  @include flexStyles(flex, row, center, center);
  @include textStyles('Montserrat arm', 20px, 500, 42px, $main-color);
  position: fixed;
  top: 65px;
  gap: 5px;
  cursor: pointer;
  background-color: $employees-bg;
  z-index: 1;
  border-bottom: 1px solid;
}

@media screen and (max-width: 1200px) {
  .textbtn {
    margin-bottom: 20px;
  }

  .emptext {
    margin-bottom: 10px;
  }

  .btn {
    width: fit-content;
  }
}

@media screen and (max-width: 920px) {
  .container {
    padding: 0;
    gap: 0;
  }

  .btn {
    padding: 0 24px 32px;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .mob-emp__cont {
    @include flexStyles(flex, column, normal, space-between);
    min-height: 100vh;
    height: auto;
  }
}