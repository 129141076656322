@import "../../Styles/mixin";
@import "../../Styles/variables";

.groups-page {
  width: 100%;
  background-color: $employees-bg;
  padding: 40px 115px 0 55px;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;

  & .searchContainer {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 20px;
  }

  & .heading {
    @include flexStyles(flex, row, center, space-between);
    margin-bottom: 50px;

    & .title {
      @include textStyles("Montserrat arm", 30px, 500, 42px, $darkBlue);
    }

    & .create-new-group-btn {
      @include textStyles("Montserrat arm", 16px, 700, 16px, $white);
      min-width: 330px;
      padding: 16px 24px;
      background: $create-group-btn-bg;
      border-radius: 90px;
      text-align: center;
      text-decoration: none;
    }
  }
}

.mobile-group-content {
  @include flexStyles(flex, column, center, space-between);
  min-height: 92vh;
  padding-top: 60px;
  & .create-new-group-btn {
    @include textStyles("Montserrat arm", 16px, 700, 16px, $white);
    min-width: 330px;
    padding: 16px 24px;
    background: $create-group-btn-bg;
    border-radius: 90px;
    text-align: center;
    text-decoration: none;
  }
}

@media screen and (max-width: 1600px) {
  .groups-page {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .groups-page {
    padding: 28px 46px 30px 45px;
  }
}

@media screen and (max-width: 1024px) {
  .heading {
    @include flexStyles(
      flex,
      column !important,
      flex-start !important,
      space-between
    );
    margin-bottom: 20px;

    & .title {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 920px){
  .mobile-group-content {
   padding-bottom: 20px
  }
}

@media screen and (max-width: 578px) {
  .mobile-group-content {
    padding-bottom: 32px;
    & .create-new-group-btn {
      width: 92% !important;
      min-width: auto;
      margin-top: 20px;
    }
  }
}
