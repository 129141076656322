@import "../../../../Styles//variables";

.container {
  width: 341px;
}

.inputStyle {
  background-color: $prime-button-text;
  flex-direction: row-reverse;
  flex: 0.98;
}

.searchIcon {
  width: 14px;
  height: 14px;
  display: flex;
}
