@import '../../Styles/mixin';
@import '../../Styles/variables';

.no-data__cont {
  @include flexStyles(flex, row, center, center);
  width: 100%;

  & .no-data__section {
    @include flexStyles(flex, column, center, center);
    max-width: 400px;

    &-img {
      margin-bottom: 42px;
    }

    &-title {
      text-align: center;
      @include textStyles('Montserrat arm', 24px, 500, normal, $no-data-title);
      margin-bottom: 21px;
    }

    &-description {
      text-align: center;
      @include textStyles(
        'Montserrat arm',
        20px,
        500,
        normal,
        $no-data-description
      );
      margin-bottom: 34px;
    }
  }
}

@media screen and (max-width: 578px) {
  .no-data__cont {
    padding-top: 50px;

    & .no-data__section {
      @include flexStyles(flex, column, center, center);
      width: 90% !important;

      &-img {
        margin-bottom: 30px !important;
        width: 80%;
      }

      &-title {
        text-align: center;
        @include textStyles(
          'Montserrat arm',
          20px,
          500,
          normal,
          $no-data-title
        );
        margin-bottom: 16px;
      }

      &-description {
        text-align: center;
        @include textStyles(
          'Montserrat arm',
          18px,
          500,
          normal,
          $no-data-description
        );
      }
    }
  }
}
