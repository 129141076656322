@import 'Styles/mixin';
@import 'Styles/variables';

.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $employees-bg;
  padding: 40px 55px;
}

.userBox {
  @include flexStyles(flex, column, normal, normal);
  gap: 40px;
}

.userProfile {
  @include flexStyles(flex, column, normal, normal);
  gap: 40px;
}

.userEditBox {
  @include flexStyles(flex, column, normal, normal);
  gap: 40px;
}

.userInfo {
  padding: 0;
  @include flexStyles(flex, row, center, space-between);
}

.userEdit {
  @include flexStyles(flex, row, center, normal);
  gap: 30px;
}

.breadCrumbBox {
  @include flexStyles(flex, row, center, space-between);
}

.title {
  @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
  letter-spacing: -0.6px;
  margin-bottom: 54px;
}

.userImageBox {
  width: 290px;
  height: 310px;
}

.userImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  background-size: contain;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
}

.buttonBox {
  @include flexStyles(flex, column, normal, normal);
  gap: 12px;
}

.editButtons {
  @include flexStyles(flex, row, center, normal);
  gap: 8px;
  @include textStyles('Montserrat arm', 16px, 600, normal, $main-color);
  cursor: pointer;
}

.buttonContent {
  @include flexStyles(flex, row, center, flex-start);
  gap: 20px;
}

.withoutBtn {
  padding: 12px 30px;
  width: auto;
  border-color: $main-color;
  border-width: 3px;
}

.withoutBtnTitle {
  color: $main-color;
}

.btn {
  padding-left: 40px;
  padding-right: 30px;
  max-width: 200px;
}

@media screen and (max-width:1225px) {
  // .userImageBox {
  //   width: 220px;
  //   height: 260px;
  // }
}

@media screen and (max-width:1100px) {
  .userBox {
    gap: 0;
  }
}

@media screen and (max-width:920px) {
  .container {
    @include flexStyles(flex, column, normal, normal);
    background-color: $prime-button-text;
    padding: 0;
  }

  .title {
    display: none;
  }

  .userProfile {
    padding: 80px 24px;
  }

  .userEditBox {
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
  }
  .userEdit {
    justify-content: center;
    gap: 30px;
    width: 100%;
  }
  .userImageBox {
    display: none;
  }
  .buttonContent {
    @include flexStyles(flex, column, center, center);
    width: 50vw;
  }
  .withoutBtn {
    display: none;
  }
  .btn {
    width: 100%;
    max-width: 100%;
  }

  .mobileBox{
    padding-top: 50px;
  }
}

@media screen and (max-width:620px) {
  .buttonContent {
    width: 75%;
  }
}

@media screen and (max-width:520px) {
  .userEditBox {
    height: 92vh;
  }
  .buttonContent {
    width: 100%;
  }
}