@import 'Styles/variables';
@import 'Styles/mixin';

.container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: $employees-bg;
    padding: 40px 55px;
}

.NotificationsTitle {
    @include textStyles('Montserrat arm', 30px, 500, 42px, $darkBlue);
    margin-bottom: 32px;
}

.resoponsive_container {
    padding-top: 50px;
}

@media screen and (max-width:920px) {
    .container {
        padding: 0;
    }
}

@media screen and (max-width:578px) {
    .resoponsive_container {
        padding-top: 80p;
    }
}