@import 'Styles/variables';
@import 'Styles/mixin';


.container {
  width: 100vw;
  height: 100vh;
  padding:0 10px;
  @include flexStyles(flex,column,normal,center);
}

.chat-container {
  display: flex;
  border: 2px solid $dark-grey;
  border-radius: 4px;
  padding: 10px;
}

.devider {
  width: 1px;
  background: $dark-grey;
}

.chat-list__container {
  width: 20%;

  & .create-group-chat-btn__cont {
    @include flexStyles(flex, row, center, flex-start);

    &:hover {
      cursor: pointer;
    }

    & span {
      @include textStyles('Montserrat arm', 16px, 600, normal, $main-color);
    }
  }

  & .plus-icon {
    @include sizes(20px, 20px);
    margin-right: 5px;
  }
}
