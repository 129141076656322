@import "../../Styles/mixin";
@import "../../Styles/variables";

.container {
    padding: 12px;
    margin-top: 12px;
    @include flexStyles(flex, row, normal, normal);
    gap: 15px;
    color: $darkBlue;
    border-top: 2px solid #D1D3D4;
}

.logOutIcon {
    width: 24px;
    height: 24px;
}

.logOutText {
    @include textStyles('Montserrat arm', 18px, 600, 24px, $darkBlue);
    &:hover{
        cursor: pointer;
    }
}

@media screen and (max-height:790px) {
    .logOutText {
      font-size: 16px;
    }
  }

@media screen and (max-width: 550px){
    .logOutText {
        @include textStyles('Montserrat arm', 16px, 500, 24px, $darkBlue);
    }
}
@media screen and (max-height:790px){
    .logOutText{
        font-style: 16px;
    }
}
@media screen and (max-width: 920px){
    .container{
  padding-left: 15px;
    }
  }