@import "../Styles/variables";
@import "../Styles/mixin";

.autoCompleteContainer .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100% !important;
  height: inherit !important;
}

.editForm {
  .autoCompleteContainer .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: $white !important;
  }
}

.Mui-disabled {
  background: $white !important;
}

.autoCompleteContainer
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding-block: 1px !important;
  padding-inline: 2px !important;
  height: inherit !important;
  font-family: "Montserrat arm" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  color: $dark-grey !important;
  outline: none !important;
  border: none !important;
}

.autoCompleteContainer
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.autoCompleteContainer .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.autoCompleteContainer .MuiAutocomplete-popupIndicator {
  color: $dark-grey !important;
}

.autoCompleteContainer .MuiAutocomplete-root {
  width: 100% !important;
  border-radius: 50px !important;
  margin-top: 8px !important;
  border: 1px solid transparent !important;
}

.autoCompleteContainer .MuiFormControl-root .MuiInputBase-root {
  width: 100% !important;
  padding: 16px !important;
}

.autoCompleteContainer .MuiFormLabel-root {
  font-family: "Montserrat arm";
  font-size: 14px !important;
  font-weight: 400;
  line-height: 15px !important;
  color: $text-grey;
}

#referenceForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root,
#vacationForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root,
#dismissalForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root,
#absenceForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root {
  background-color: $white !important;
}

.autoCompleteContainer .Mui-focused {
  border: 1px solid $main-color !important;
}

.autoCompleteContainer .Mui-focused.MuiAutocomplete-root {
  border: none !important;
}

.autoCompleteContainer .Mui-focused.MuiFormLabel-root {
  display: none !important;
}

.autoCompleteContainer .MuiInputLabel-shrink {
  display: none !important;
  max-width: 100% !important;
  border: 2px solid red !important;
}

.mainContainer .MuiTab-root {
  max-width: 20% !important;
  min-width: 20% !important;
  @include textStyles(
    "Montserrat arm",
    1.25vw,
    600,
    normal,
    $dark-grey !important
  );
  text-transform: none !important;
}

.mainContainer .Mui-selected {
  color: $darkBlue !important;
}

.applications_container__NXjTR {
  padding: 20px 55px 20px 55px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white-input-bg inset !important;
  -webkit-text-fill-color: $dark-grey !important;
}

.navList .active {
  color: $dark-grey !important;
}

.accordion
  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.MuiAccordionSummary-content {
  margin: 0 !important;
}

.accordion .MuiAccordionSummary-root {
  border-radius: 5px !important;
  min-height: 40px !important;
  margin-bottom: 10px !important;
}

.breadCrumbContainer {
  margin-bottom: 34px;
}

.breadCrumbContainer .MuiTypography-root-MuiBreadcrumbs-root {
  font-family: "Montserrat arm" !important;
  font-size: 1vw !important;
  font-weight: 400 !important;
  color: $text-grey;
}

.breadCrumbContainer .MuiBreadcrumbs-separator {
  color: $text-grey;
}

.breadCrumbContainer .MuiTypography-root {
  @include textStyles(
    "Montserrat arm",
    1vw,
    400,
    normal,
    $dark-grey !important
  );
}

.breadCrumbContainer .MuiBreadcrumbs-li {
  color: $text-grey !important;
}

.tableContainer .MuiTableRow-root th {
  @include textStyles(
    "Montserrat arm",
    1vw,
    400,
    normal,
    $dark-grey !important
  );
  border-bottom: 1px solid rgba(28, 28, 28, 0.2) !important;
}

.tableContainer .MuiTableRow-root td {
  @include textStyles("Montserrat arm", 0.83vw, 400, normal, $black !important);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5) !important;
}

.orderTable .MuiTable-root {
  border-collapse: separate;
  border-spacing: 0 10px;
  border: transparent;
}

.orderTable .MuiTableRow-root th {
  @include textStyles(
    "Montserrat arm",
    1.1vw,
    600,
    normal,
    $dark-grey !important
  );
  border-bottom: 1px solid rgba(28, 28, 28, 0.2) !important;
  max-width: 100px !important;
  text-align: center;
}

.orderTable .MuiTableRow-root td {
  @include textStyles("Montserrat arm", 0.83vw, 400, normal, $black !important);
  // font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  text-align: center;
}

//modal css
.group-teamleads-modal .MuiPaper-root {
  min-width: 25vw;
}

.group-teamleads-modal .MuiDialogContent-root {
  padding: 0 24px 24px;
  border: unset;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.group-teamleads-modal .MuiDialogTitle-root {
  padding: 24px;
  color: #2a3342;
  font-family: "Montserrat arm";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}

//settingsModal styles
.settingsModal .MuiDialogTitle-root {
  @include textStyles(
    "Montserrat arm" !important,
    24px !important,
    500 !important,
    normal !important,
    #006af5 !important
  );
  font-style: normal !important;
  padding: 0 !important;
}

.settingsModal .MuiDialogContent-root {
  padding: 0 !important;
  border: unset !important;
}

.settingsModal input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #edf2f7 inset !important;
  -webkit-text-fill-color: $dark-grey !important;
}

.settingsModal .MuiPaper-root {
  min-width: 375px !important;
  // min-width: 325px;
  padding: 24px !important;
}

.dyrectoryModal .MuiPaper-root {
  min-width: 400px !important;
  padding: 24px !important;
}

.changeStatusDialog .MuiDialogContent-root {
  padding: 24px 0 14px !important;
  border: unset !important;
}

.choseBlockTypeDialog .MuiDialogContent-root {
  background: red;
}

.changeStatusDialog .MuiDialogTitle-root {
  @include textStyles(
    "Montserrat arm" !important,
    24px !important,
    500 !important,
    normal !important,
    #006af5 !important
  );
  font-style: normal !important;
  padding: 0 !important;
  max-width: 85%;
}

.changeStatusDialog .MuiDialogActions-root {
  padding: 0 !important;
}

.changeStatusDialog .MuiPaper-root {
  min-width: 35% !important;
  padding: 24px !important;
}

.page {
  width: 100%;
  background-color: $employees-bg;
  padding: 40px 115px 0 55px;
  min-height: 100vh;
  height: 100%;
}

.add-employee-form .MuiInputBase-root {
  background: $white !important;
}

.formBox .MuiInputBase-root {
  background: $white !important;
}

.str-chat {
  width: 100% !important;
  height: 90vh !important;
}

.str-chat-channel {
  width: 80% !important;
  height: 90vh !important;
}

.str-chat__message--has-text.pinned-message {
  & .str-chat__message-bubble {
    background-color: rgb(5, 150, 199) !important;
  }
}

--me.str-chat__message--has-text.pinned-message {
  & .str-chat__message-bubble {
    background-color: rgb(5, 150, 199) !important;
  }
}

.str-chat__channel-preview--active,
.str-chat__channel-preview-messenger--active {
  background-color: #e7e9ed;
}

.str-chat__edit-message-form .str-chat__edit-message-form-options {
  gap: 15px;
}

.MuiButtonBase-root {
  span {
    color: $light-grey !important;
  }
}

.MuiStepper-root {
  @include textStyles(
    "Montserrat arm" !important,
    18px !important,
    500 !important,
    normal,
    $grey-blue !important
  );
}

.stepper .Mui-disabled {
  background-color: transparent !important;
}

.stepper .Mui-active {
  font-size: 22px !important;
  svg {
    width: 50px !important;
    height: 50px !important;
  }
}

.MuiStepButton-root {
  background-color: transparent !important;
}

.mobile-tasks-dashboard__cont .MuiBox-root {
  @include flexStyles(flex, column, flex-start, flex-start);
  width: 100% !important;

  .MuiButtonBase-root {
    flex: 1;
    width: 100% !important;
  }

  .MuiTabs-root {
    min-width: 100% !important;
  }

  .MuiTypography-root {
    width: 100% !important;
    & > div {
      @include flexStyles(flex, row, center, space-around);
      flex-wrap: wrap;
      width: 100% !important;
    }
  }

  #simple-tabpanel-1 {
    width: 100%;
  }
}

.employeeSettingForm .MuiCheckbox-root {
  @include textStyles(
    "Montserrat arm" !important,
    18px !important,
    500 !important,
    normal !important,
    #006af5 !important
  );
}

.employeeFilter .MuiPaper-root-MuiDrawer-pape {
  overflow: scroll !important;
}

.react-flow {
  overflow: visible !important;
}

.react-flow__renderer {
  left: 50% !important;
  right: 50% !important;
}

.react-flow__panel {
  display: none;
}

.employeeInfoModal .MuiPaper-root {
  min-width: 30% !important;
}

.employeeInfoModal .MuiAutocomplete-listbox {
  max-height: 100px !important;
}

.hierarchyModal .MuiPaper-root {
  width: auto !important;
}

.hierarchyModal .MuiDialogContent-root {
  width: 400px !important;
}

.hierarchyModal .MuiAutocomplete-listbox {
  max-height: 150px !important;
}

.hierarchy {
  ::-webkit-scrollbar {
    position: fixed !important;
    bottom: 0;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $disable-button;
    border-radius: 25px;
    width: 50px;
  }

  ::-webkit-scrollbar {
    height: 20px !important;
  }
}

.document-reductor {
  margin-bottom: 15px;
  .quill {
    .ql-container {
      .ql-editor {
        min-height: 200px !important
      }
    }
  }
}

/* media */

@media screen and (max-width: 920px) {
  .page {
    width: 100%;
    background-color: $white;
    padding: 0px;
  }

  #referenceForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root,
  #vacationForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root,
  #dismissalForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root,
  #absenceForm .autoCompleteContainer .MuiFormControl-root .MuiInputBase-root {
    background-color: $employees-bg !important;
  }

  .mainContainer .MuiTab-root {
    font-size: 14px !important;
    font-weight: 500 !important;
    max-width: 25% !important;
    min-width: none !important;
  }

  .changeStatusDialog .MuiPaper-root {
    min-width: 45% !important;
  }

  .tableContainer .MuiTableRow-root th {
    font-size: 12px !important;
  }

  .tableContainer .MuiTableRow-root td {
    font-size: 10px !important;
  }

  .str-chat-channel {
    width: 100% !important;
    height: 85vh !important;
  }

  .str-chat__channel-header {
    width: 95% !important;
    position: fixed !important;
    top: 65.5px !important;
    z-index: 1 !important;
    border-bottom: 1px solid $dark-grey !important;
  }

  .str-chat__modal .str-chat__modal__inner {
    width: 70vw !important;
  }

  .simple-tabpanel-1,
  .simple-tabpanel-2,
  .simple-tabpanel-3 {
    width: 100% !important;
  }
}

@media screen and (max-width: 750px) {
  .changeStatusDialog .MuiPaper-root {
    min-width: 60% !important;
  }
}

@media screen and (max-width: 680px) {
  .tableContainer .MuiTableRow-root th {
    font-size: 10px !important;
  }

  .tableContainer .MuiTableRow-root td {
    font-size: 8px !important;
  }
}

@media screen and (max-height: 710px) {
  .accordion .MuiAccordionSummary-root {
    min-height: 35px !important;
  }
}

@media screen and (max-width: 620px) {
  .mainContainer .MuiTab-root {
    font-size: 10.69px !important;
  }
  .employeeFilter .MuiDrawer-paper {
    width: 70vw !important;
  }
}

@media screen and (max-width: 520px) {
  .mainContainer .MuiTab-root {
    min-width: 28% !important;
  }

  .changeStatusDialog .MuiPaper-root {
    min-width: 80% !important;
  }
}

@media screen and (max-width: 480px) {
  .MuiBox-root {
    padding: 0 !important;
  }

  .mekum-modal .MuiDialogTitle-root {
    font-size: 20px;
  }

  .group-teamleads-modal .MuiDialogTitle-root {
    font-size: 20px;
  }

  .mekum-modal .MuiDialogTitle-root {
    @include textStyles(
      "Montserrat arm" !important,
      20px !important,
      500 !important,
      normal !important,
      #006af5 !important
    );
  }

  .mekum-modal .MuiTypography-root {
    font-size: 14px;
  }

  .mobile-tasks-dashboard__cont .css-19kzrtu {
    padding: 24px !important;
  }

  #simple-tabpanel-0,
  #simple-tabpanel-1,
  #simple-tabpanel-2 {
    width: 100% !important;
  }
}

@media screen and (max-width: 440px) {
  .dyrectoryModal .MuiPaper-root {
    min-width: 80% !important;
  }
}

@media screen and (max-width: 390px) {
  .dyrectoryModal .MuiPaper-root {
    padding: 5px !important;
  }
}

@media screen and (max-width: 350px) {
  /* otpInput */
  .MuiInputBase-root.MuiOutlinedInput-root {
    width: 45px !important;
    height: 45px !important;
    font-size: 28px !important;
  }

  .settingsModal .MuiDialogTitle-root {
    font-size: 20px;
  }
}
