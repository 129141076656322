@import 'Styles/mixin';
@import 'Styles/variables';

.page {
    width: 100%;
    max-width: 100%;
    background-color: $employees-bg;
    height: 100%;
    gap: 40px;
    padding: 10px;
}

.body {
    min-height: 100vh;
    max-width: 100vw;
    @include flexStyles(flex, column, normal, normal);
    padding: 40px 40px 0 55px;
    gap: 40px;
}

.rightSideContainer {
    @include flexStyles(flex, column, center, center);
    padding: 10px;
    gap: 30px;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 20vw;
    max-width: 20vw;
    background-color: $white;
    position: fixed;
    right: 0;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
}


.header {
    @include flexStyles(flex, column, normal, normal);
    gap: 20px
}

.title {
    @include textStyles('Montserrat arm', 30px, 400, 42px, $darkBlue);
}

.describtiom {
    @include textStyles('Montserrat arm', 1.1vw, 400, normal, $darkBlue);
    max-width: 60%;
}

.directoryList {
    width: 100%;
    @include flexStyles(flex, column, center, center);
    gap: 10px;
}

.directoryTittle {
    @include textStyles('Montserrat arm',16px, 500, normal, $darkBlue);
    width: 30%;
}

.accordion {
    width: 50%
}

.summary {
    width: 100%;
    @include flexStyles(flex, row, center, normal);
    gap: 50px;
}

.accordionSelect {
    width: 40%;
    stroke-width: 1px;
    background-color: $status-finished-bg;
    border: none;
    outline: none;
    padding: 5px 15px;
    appearance: none;
    @include textStyles("Montserrat arm", 14px, 500, 20px, $disable-button);
    cursor: pointer;
    @include backgroundStyles(url("../../Assets/Icons/selectArrow.png"), 0.65rem auto, right 0.7rem top 50%, no-repeat);
}

.accordionSelect option {
    background-color: $status-finished-bg;
}

.accordionSelect option:first {
    display: none
}

.accordionSelect option:disabled {
    @include textStyles("Montserrat arm", 14px, 500, 15px, $disable-button);

}

.mobileContainer {
    z-index: 0;
    background-color: $status-finished-bg;
  }



.formContainer {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
    background-color: $prime-button-text;
    border-radius: 15px;
    box-shadow: 0px 5px 2px 0px rgba(85, 105, 135, 0.15);
    padding: 20px;
    width: 50%;
}

.editForm {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
    background-color: $white;
    box-shadow: none;
    padding: 20px;
    width: 100%;
}


.form {
    @include flexStyles(flex, column, normal, normal);
    gap: 40px;
}

.formHeader {
    @include flexStyles(flex, row, normal, space-between);
}

.formTitle {
    @include textStyles('Montserrat arm', 20px, 600, normal, $marengo);
}

.inputIcons {
    @include flexStyles(flex, row, normal, normal);
    gap: 10px;
}

.inputIcon{
    max-height: 20px;
    min-width: 20px;
}

.inputIcon:hover {
    transform: scale(1.1);
}

.closeIcon {
    margin-top: -10px;
    cursor: pointer;
    transition: 0.2s;
}

.closeIcon:hover {
    transform: scale(1.1)
}

.radioButtons {
    @include flexStyles(flex, column, normal, normal);
    gap: 10px;
}

.radioLabel {
    @include textStyles('Montserrat arm', 14px, 400, normal, $darkBlue);
}

.optionList {
    @include flexStyles(flex, column, normal, normal);
    gap: 10px;
}

.optionListTitle {
    @include flexStyles(flex, row, normal, flex-end);
    gap: 10px;
}

.addIconRow {
    width: 100%;
    @include flexStyles(flex, row, center, flex-end);
    margin-top: -30px;

}

.addIcon {
    @include flexStyles(flex, row, center, flex-end);
    gap: 10px;
    cursor: pointer;
}

.addIcon:hover {
    transform: scale(1.05)
}

.optionsLabel {
    @include textStyles('Montserrat arm', 16px, 600, normal, $marengo);
    margin-bottom: 20px;
}

.addText {
    @include textStyles('Montserrat arm', 16px, 500, normal, $main_color);
}

.buttonRow {
    @include flexStyles(flex, row, normal, flex-end);
    gap: 20px;
}

.button {
    width: fit-content;
    padding: 10px;
    font-size: 15px;
}

.button:hover {
    transform: scale(1.05)
}

.createButton {
    position: fixed;
    width: fit-content;
    padding: 20px;
    font-size: 15px;
    bottom: 20px;
    right: 35px;
}

.createButton:hover {
    transform: scale(1.05);
}

.directoryOpton {
    @include flexStyles(flex, row, center, space-between);
    @include textStyles('Montserrat arm', 14px, 500, 15px, $dark-grey);
    border: 1px solid transparent;
    width: 100%;
    margin-top: 8px;
    padding: 16px;
    background: $input_bg;
}

//Madia

@media screen and (max-width:1325px) {
    .formContainer {
        width: 70%;
    }
    .accordion {
        width: 70%;
    }
}

@media screen and (max-width:1060px) {
    .accordion {
        width: 80%;
    }
    .formContainer {
        width: 80%;
    }
    
}

@media screen and (max-width:990px) {
    .accordion {
        width: 90%;
    }
    .formContainer {
        width: 90%;
    }
}


@media screen and (max-width: 920px) {
    .page {
      padding: 0;
      gap: 0;
      height: 100vh;
      background-color: $status-finished-bg;
    }
    .directoryList {
       margin-top: 65px;
       padding: 20px 25px;
       height: 70%;
       max-height: 70%;
       background-color: $status-finished-bg;
       overflow: scroll;
    }
    .createButton {
        font-size: 15px;
        bottom: 10px;
        right: 10px;
    }
    .accordion {
        width: 70%;
    }
    .formContainer {
        width: 70%;
    }
  }

  @media screen and (max-width: 650px) {
    .accordion {
        width: 100%
    }
    .formContainer {
        width: 100%;
    }
    
  }

  @media screen and (max-width: 490px) {
    .summary {
        width: 100%;
        @include flexStyles(flex, row, center, space-between);
        gap: 10px;
        padding: 0px 10px 0 0;
    }
    
    .accordionSelect {
        width: 45%;
        font-size: 12px;
        padding: 5px;
    }
    .directoryTittle {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 400px) {
    .directoryList {
        padding: 20px 10px;
     }
  }