@import "Styles/variables";
@import "Styles/mixin";


.conteiner{
 @include flexStyles(flex, column, flex-start, space-between);
 box-shadow: 0px 0.817px 1.635px 0px rgba(85, 105, 135, 0.20);
 border-radius: 4px;
 padding: 10px 16px 16px 16px ;
}
.conteinerItem{
    @include flexStyles(flex, row, flex-start, space-between);
    width: 100%;
}
.iconDiv{
    @include sizes(32px, 32px);
}
.titleDiv{
    margin: 7px 0;
}
.textStyle{
    @include  textStyles ("Montserrat arm", 14px, 400, normal, $grey-blue)
}
.title{
    @include  textStyles ("Montserrat arm", 16px, 600, normal, $dark-grey)
}
