@import "../../Styles/mixin";
@import "../../Styles/variables";

.container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  gap: 35px;
  @include flexStyles(flex, column, normal, normal);
  padding: 40px 55px;
  background-color: $input_bg;
  overflow: scroll;
  overflow-x: hidden;
}

.form {
  width: 100%;
  flex: 1;
  @include flexStyles(flex, column, normal, normal);
}

@media screen and (max-width: 920px) {
  .container {
    padding: 0;
    align-items: center;
  }

  .form {
    align-items: center;
    margin-top:80px
  }
}

@media screen and (max-width: 580px) {
  .container {
    gap: 0;
    background-color: $prime-button-text;
  }

  .form {
    margin-top: 50px;
    padding: 43px 24px 32px;
  }
}