@import '../../Styles/variables';
@import '../../Styles/mixin';

.container {
    @include flexStyles(flex, row, center, space-between);
    width: 100%;
    padding: 12px 24px;
    background-color: $white;
    border-radius: 7px;
    margin-top: 12px;
    flex-wrap: wrap;
    gap: 10px;
    input {
        display: none;
    }
}

.mobileBox {
    @include flexStyles(flex, column, normal, space-between);
    width: 100%;
}

.mobileUpload{
    width: 100%;
    padding: 12px 24px;
    background-color: $white;
    border-radius: 7px;
    margin-top: 12px;
    border: 2px dashed $employees-bg;
    text-align: center;
    @include textStyles('Montserrat arm', 14px, 400, normal, $main-color);
    input {
        display: none;
    }
}

.uploadFile {
    @include textStyles('Montserrat arm', 14px, 500, 44px, $main-color);
    cursor: pointer;
    border-radius: 5px;
    background-color: $white;
    border: 1px solid #E6E8EC;
    padding: 15px 20px;
}

.label {
    @include textStyles('Montserrat arm', 14px, 400, normal, $darkBlue);
    will-change: 100%;
  }

.disabled{
    color: $text-grey;
    cursor: default;
}
.placeholder{
    @include textStyles('Montserrat arm', 14px, 400, 15px, $text-grey);
    cursor: default
}
.files {
    @include flexStyles(flex, row, center, flex-start);
    flex-wrap: wrap;
    gap: 25px;
    p {
        @include textStyles('Montserrat arm', 16px, 400, 24px, $dark-grey);
        span{
            @include textStyles('Montserrat arm', 16px, 500, 24px, #FF234B);
            cursor: pointer;
            margin-left: 5px;
        }
    }
}

.mobileFiles{
    @include flexStyles(flex, row, normal, flex-start);
    gap: 25px;
    margin-top: 15px;
    p {
        @include textStyles('Montserrat arm', 16px, 400, 24px, $dark-grey);
        span{
            @include textStyles('Montserrat arm', 16px, 500, 24px, #FF234B);
            cursor: pointer;
            margin-left: 5px;
        }
    }
}
