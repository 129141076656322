@import '../../Styles/mixin';
@import '../../Styles/variables';

.drawer-company__item {
  padding: 0 40px;
  @include flexStyles(flex, row, center, space-between);
  margin-bottom: 16px;

  &:hover {
    cursor: pointer;
  }

  &-left-part {
    @include flexStyles(flex, row, center, flex-start);

    & img {
      margin-right: 16px;
      @include sizes(32px, 32px);
      border-radius: 100%;
    }

    & .drawer-company__item-name {
      @include textStyles('Montserrat arm', 14px, 500, normal, $dark-grey);
    }
  }

  & .drawer-company__item-current {
    @include flexStyles(flex, row, center, center);
  }
}

.drawer-company-list__container {
  padding-top: 20px;
} 

.drawer-create-company__container {
  padding: 0 40px;
  @include flexStyles(flex, row, center, flex-start);

  & .icon {
    margin-right: 11px;
  }

  & .text {
    @include textStyles('Montserrat arm', 15px, 500, normal, $dark-grey);
  }
}

.drawer-companies-container {
  border-bottom: 1px solid $light-grey;
padding-bottom: 20px;
  & a {
    text-decoration: none;
  }
}
